import { COMP_NAV_CREATED } from "../actions/companies";

export default (state = null, action = {}) => {
  switch (action.type) {
    case COMP_NAV_CREATED:
      return action.payload;
    default:
      return state;
  }
};
