import { getWeek } from "../Utils/functions";
import {
    DRIVER_WEEKLY_STATS_FETCHED,
    DRIVER_WEEKLY_STATS_RESETTED,
} from "../actions/driverStats";

let statsWithWeekIndexes;
let modifiedStat;

export default (state = null, action = {}) => {
    switch (action.type) {
        case DRIVER_WEEKLY_STATS_FETCHED:
            if (action.payload && action.payload.length > 0) {
                statsWithWeekIndexes=action.payload?.map((statType)=>{
                    modifiedStat=statType.statistics.map((stat)=>{
                        return{
                            ...stat,
                            week:getWeek(new Date(stat.start))
                        }
                    })
                    return {
                        ...statType,
                        statistics:[...modifiedStat]
                    }
                })
                return statsWithWeekIndexes;
            } else {
                return [];
            }
        case DRIVER_WEEKLY_STATS_RESETTED:
            return null;
        default:
            return state;
    }
};