import { DRIVER_VS_MONTHS_DATA_FETCHED, RESET_DRIVER_VS_MONTHS,APPLY_SEARCH_ON_DRIVER_VS_MONTHS,CLEAR_SEARCH_ON_DRIVER_VS_MONTHS } from "../actions/scoreOverview";

let driverVsMonthsState = {
  loading: false,
  data: [],
  date: {},
  fullDriverList:[]
}

let filteredData=[];

export default (state = driverVsMonthsState, action = {}) => {
  switch (action.type) {
    case DRIVER_VS_MONTHS_DATA_FETCHED:
      return {
        ...state,
        date: action.payload.date,
        data: action.payload.data,
        fullDriverList:action.payload.data
      };
    case RESET_DRIVER_VS_MONTHS:
      return {
        ...driverVsMonthsState
      };
    case APPLY_SEARCH_ON_DRIVER_VS_MONTHS:
      filteredData=state?.fullDriverList?.filter((driver)=>driver.driversFirstName.toLowerCase().includes(action.payload.toLowerCase()) || driver.driversSurName.toLowerCase().includes(action.payload.toLowerCase()))
      if(!filteredData){
        return state;
      }
      return {
         ...state,
         data:[...filteredData]
      }
    case CLEAR_SEARCH_ON_DRIVER_VS_MONTHS:
      return {
        ...state,
        data:[...state.fullDriverList]
      }
    default:
      return state;
  }
};