import {
  DRIVER_STATS_FETCHED,
  DRIVER_STATS_HISTORY_RESETTED,
  DRIVER_STATS_HISTORY_FETCHED,
} from "../actions/driverStats";

let seen;
let withoutDuplicates;
let duplicate;

export default (state = null, action = {}) => {
  switch (action.type) {
    case DRIVER_STATS_FETCHED:
      if (action.payload && action.payload.length > 0) {
        // filtering out duplicate statistics
        seen = new Set();
        withoutDuplicates = action.payload.filter((el) => {
          duplicate = seen.has(el.name);
          seen.add(el.name);
          return !duplicate;
        });
        return withoutDuplicates;
      } else {
        return [];
      }
    case DRIVER_STATS_HISTORY_FETCHED:
      if (action.payload && action.payload.length > 0) {
        // filtering out duplicate statistics
        seen = new Set();
        withoutDuplicates = action.payload.filter((el) => {
          duplicate = seen.has(el.name);
          seen.add(el.name);
          return !duplicate;
        });
        return withoutDuplicates;
      } else {
        return [];
      }
    case DRIVER_STATS_HISTORY_RESETTED:
      return null;
    default:
      return state;
  }
};
