import { MONTHLY_SCORES_FETCHED, MONTHLY_SCORES_RESETTED } from "../actions/scores";

let modifiedScoreswithMonthNames;
let modifiedScores;
export default (state = null, action = {}) => {
    switch (action.type) {
        case MONTHLY_SCORES_FETCHED:
            modifiedScoreswithMonthNames = action.payload?.map((behaviour) => {
                modifiedScores = behaviour.scores?.map((score) => {
                    const month = new Date(score.start).toLocaleString('en-us', { month: 'short' });
                    const year = new Date(score.start).getFullYear();
                    return {
                        ...score,
                        monthInfo:{
                            month: month,
                            year: year
                        }   
                    };                    
                })
                return {
                    ...behaviour,
                    scores:[...modifiedScores]
                }
            })
            return modifiedScoreswithMonthNames;
        case MONTHLY_SCORES_RESETTED:
            return null;
        default:
            return state;
    }
};
