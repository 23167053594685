import { DashboardMinGoalSettingsDataFetched } from "../actions/dashboardGraphs";

let minGoalGraphDataForDashboard = {
  companyData:{},
  minGoalSettings: {},
}
export default (state = minGoalGraphDataForDashboard, action = {}) => {
  switch (action.type) {
    case DashboardMinGoalSettingsDataFetched:
      return {
        ...state,
        minGoalSettings: { ...state.minGoalSettings, ...action.payload.data },
        companyData:{...state.companyData,...action.payload.company}
      };
    default:
      return state;
  }
};
