import { getWeek } from "../Utils/functions";
import { WEEKLY_SCORE_HISTORY_FETCHED, WEEKLY_SCORE_HISTORY_RESETTED } from "../actions/scoreHistory";

let scoresWithWeekIndex;
let modifiedScores;
export default (state = null, action = {}) => {
    switch (action.type) {
        case WEEKLY_SCORE_HISTORY_FETCHED:
            scoresWithWeekIndex=action.payload.data?.map((behaviour)=>{
                modifiedScores=behaviour.scores.map((score)=>{
                    return{
                        ...score,
                        week:getWeek(new Date(score.start))
                    }
                })
                return {
                    ...behaviour,
                    scores:[...modifiedScores]
                }
            })
            return scoresWithWeekIndex;
        case WEEKLY_SCORE_HISTORY_RESETTED:
            return null;
        default:
            return state;
    }
}