import { DashboardAllScoresDataFetched} from "../actions/dashboardGraphs";
// let pieData={};
let graphDataForDashboard = {
  companyData:{},
  allScores: {},
}
export default (state = graphDataForDashboard, action = {}) => {
  switch (action.type) {
    case DashboardAllScoresDataFetched:
      return {
        ...state,
        allScores: { ...state.allScores, ...action.payload.data },
        companyData:{...state.companyData,...action.payload.company}
      };
    default:
      return state;
  }
};
