import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import i18n from "../../../i18n";
import { getAccessToken } from "../../../services/commonFunctions";
import { getDriverList } from "../ManagementWithScores/actions/driversForSidebar";
// import {fetchGroupSettings} from "./fetchGroupSettings"
let accounts;
// fetching the company groups
export const COMPANY_GROUPS_FETCHED = "COMPANY_GROUPS_FETCHED";

const companyGroupsFetched = (groups) => ({
  type: COMPANY_GROUPS_FETCHED,
  payload: groups,
});


export const loadCompanyGroups = (companyId, companyName) => async (dispatch, getState)=>{
  const store = getState();
  let { auth, roles } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  request
    .get(`${baseURL}/portal/management/groups/GetCompanyGroups?companyId=${companyId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from companyGroups API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      // console.log(response, companyName);
      dispatch(companyGroupsFetched(response.body));
      dispatch(createManagementNavi(response.body, companyName));
      const managementWithScores=roles.find((role)=>role.roleString === "management_with_scores")?true:false
      if (managementWithScores) {
        dispatch(getDriverList(companyId, companyName, response.body))
      }

      // fetching group settings 
      // response.body.map((group)=> dispatch(fetchGroupSettings(group)))

  })
 }

// toggling edit mode for a group
export const COMPANY_GROUP_EDITMODE_TOGGLED = "COMPANY_GROUP_EDITMODE_TOGGLED";

const companyGroupEditToggled = (groupId) => ({
  type: COMPANY_GROUP_EDITMODE_TOGGLED,
  payload: groupId,
});

export const toggleCompanyGroupEdit = (groupId) => async (dispatch) => {
  await dispatch(companyGroupEditToggled(groupId));
};

// updating the name of a company group
export const COMPANY_GROUP_NAME_UPDATED = "COMPANY_GROUP_NAME_UPDATED";

const companyGroupNameChanged = (groupId, data) => ({
  type: COMPANY_GROUP_NAME_UPDATED,
  payload: { groupId: groupId, groupsData: data },
});

export const changeCompanyGroupName = (groupId, data) => async (dispatch) => {
  await dispatch(companyGroupNameChanged(groupId, data));
};

// adding a new company group
export const COMPANY_GROUP_CREATED = "COMPANY_GROUP_CREATED";

const companyGroupCreated = (groupData) => ({
  type: COMPANY_GROUP_CREATED,
  payload: groupData,
});

export const createCompanyGroup = (groupData) => async (dispatch) => {
  await dispatch(companyGroupCreated(groupData));
};

// deleting a company group
export const COMPANY_GROUP_DELETED = "COMPANY_GROUP_DELETED";

const companyGroupDeleted = (groupId) => ({
  type: COMPANY_GROUP_DELETED,
  payload: groupId,
});

export const deleteCompanyGroup = (groupId) => async (dispatch) => {
  await dispatch(companyGroupDeleted(groupId));
};

// creating management navigation
export const MNG_NAV_CREATED = "MNG_NAV_CREATED";

const mngNavCreated = (mngNavigation) => ({
  type: MNG_NAV_CREATED,
  payload: mngNavigation,
});

const createManagementNavi = (_companyGroups, companyName) => (dispatch) => {
  const result = {
    items: [
      {
        title: true,
        name: "Management",
      },
      {
        // name: companyName
        //   ? companyName
        //   : i18n.t("translation:Company.6"),
        name: i18n.t("translation:Company.6"),
        url: `/management/${companyName}`,
        // icon: "icon-briefcase",
        icon: "icon-graph",
        attributes: {
          id: "first_nav_toggle",
          onClick: () => {
            return;
          },
          class: "management_sidebar--title",
        },
        // children:
        //   companyGroups && companyGroups.length > 0
        //     ? companyGroups.map((companyGroup) => {
        //         return {
        //           name: companyGroup.groupName,
        //           url: `/management/${companyName}/${companyGroup.groupName}`,
        //           icon: "custom-groups-o",
        //           attributes: {
        //             id: companyGroup.groupId,
        //             onClick: () => {
        //               // if the child is clicked, the company dropdown won't close
        //               setTimeout(() => {
        //                 document.getElementById(
        //                   "first_nav_toggle"
        //                 ).parentElement.className =
        //                   "nav-item nav-dropdown open";
        //               }, 100);
        //             },
        //             class: "management_sidebar--item",
        //           },
        //         };
        //       })
        //     : [],
      },
    ],
  };
  dispatch(mngNavCreated(result));
};
