import {
    DRIVER_MONTHLY_STATS_FETCHED,
    DRIVER_MONTHLY_STATS_RESETTED,
} from "../actions/driverStats";

let statsWithMonth;
let modifiedStat;
export default (state = null, action = {}) => {
    switch (action.type) {
        case DRIVER_MONTHLY_STATS_FETCHED:
            if (action.payload && action.payload.length > 0) {
                statsWithMonth = action.payload?.map((statType) => {
                    modifiedStat = statType.statistics.map((stat) => {
                        const month = new Date(stat.start).toLocaleString('en-us', { month: 'short' });
                        const year = new Date(stat.start).getFullYear();
                        return {
                            ...stat,
                            monthInfo: {
                                month: month,
                                year: year
                            }
                        }
                    })
                    return {
                        ...statType,
                        statistics: [...modifiedStat]
                    }
                })
                return statsWithMonth;
            } else {
                return [];
            }
        case DRIVER_MONTHLY_STATS_RESETTED:
            return null;
        default:
            return state;
    }
};