import { GRAPHS_FETCHED } from "../actions/graphs";
import { PieChartFetched, AllScoresDataFetched, MinGoalSettingsDataFetched, AllStatsDataFetched } from "../actions/graphs";
// let pieData={};
let graphData = {
  companyData: {},
  scoreDistributionData: {},
  allScores: {},
  minGoalSettings: {},
  allStats: {}
}
export default (state = graphData, action = {}) => {
  switch (action.type) {
    case GRAPHS_FETCHED:
      return action.payload;
    case PieChartFetched:
      // pieData = {...state.graphs.pieData,...action.payload }
      // console.log("in reducer", pieData)
      return {
        ...state,
        scoreDistributionData: { ...state.scoreDistributionData, ...action.payload }
      };
    case AllScoresDataFetched:
      return {
        ...state,
        allScores: { ...state.allScores, ...action.payload }
      };
    case MinGoalSettingsDataFetched:
      return {
        ...state,
        minGoalSettings: { ...state.minGoalSettings, ...action.payload.data },
        companyData: { ...state.companyData, ...action.payload.company }
      };
    case AllStatsDataFetched:
      return {
        ...state,
        allStats: { ...state.allStats, ...action.payload }
      }
    default:
      return state;
  }
};
