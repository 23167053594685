// import request from "superagent";
// import { authProvider } from "../../../authProvider";
// import { baseURL } from "../constants";

// fetching the inactive drivers for Management portal's inactive driver table, app apctivity tab
export const INACTIVE_DRIVERS_TABLE_FETCHED = "INACTIVE_DRIVERS_TABLE_FETCHED";

const inactiveDriversTableFetched = (drivers) => ({
  type: INACTIVE_DRIVERS_TABLE_FETCHED,
  payload: drivers,
});

export const loadInactiveDriversTable = () => async (dispatch) => {
  const dummyInactiveDrivers = [
    {
      driverId: "1",
      firstName: "Mark",
      lastName: "Atto",
      phone: "06-32345678",
      email: "default@gmail.com",
      companyId: "25637",
      group: "Group A",
      hasNote: false,
      note: null,
      contacted: false,
      contactedDate: null,
    },
    {
      driverId: "2",
      firstName: "Jacob",
      lastName: "Thornton",
      phone: "06-12345678",
      email: "bdefault@gmail.com",
      companyId: "984",
      group: "Group A",
      hasNote: true,
      note: "Note for Jacob",
      contacted: true,
      contactedDate: new Date("December 17, 1995 03:24:00"),
    },
    {
      driverId: "3",
      firstName: "Larry",
      lastName: "the Bird",
      phone: "06-92345678",
      email: "adefault@gmail.com",
      companyId: "98345",
      group: "Group B",
      hasNote: false,
      note: null,
      contacted: true,
      contactedDate: new Date("July 20, 2020 16:24:00"),
    },
    {
      driverId: "4",
      firstName: "Abel",
      lastName: "Loko",
      phone: "06-32347678",
      email: "tdefault@gmail.com",
      companyId: "2537",
      group: "Group B",
      hasNote: true,
      note: "Note for Abel",
      contacted: false,
      contactedDate: null,
    },
  ]; // will come from API
  await dispatch(inactiveDriversTableFetched(dummyInactiveDrivers));
};

// updating "Contacted" info about a driver
export const INACTIVE_DRIVER_CONTACTED = "INACTIVE_DRIVER_CONTACTED";

const inactiveDriverContactToggler = (driverId, value) => ({
  type: INACTIVE_DRIVER_CONTACTED,
  payload: { driverId: driverId, contactValue: value },
});

export const updateContactedValue = (driverId, value) => async (dispatch) => {
  await dispatch(inactiveDriverContactToggler(driverId, value));
};

// sorting the inactive drivers' table based on various options
export const INACTIVE_SORTING_CHANGED = "INACTIVE_SORTING_CHANGED";

const inactiveSortingChanged = (keyword) => ({
  type: INACTIVE_SORTING_CHANGED,
  payload: keyword,
});

export const changeInactiveSorting = (keyword) => async (dispatch) => {
  await dispatch(inactiveSortingChanged(keyword));
};

// updating note field for a driver
export const INACTIVE_DRIVER_NOTE_UPDATED = "INACTIVE_DRIVER_NOTE_UPDATED";

const inactiveDriverNoteChanged = (driverId, value) => ({
  type: INACTIVE_DRIVER_NOTE_UPDATED,
  payload: { driverId: driverId, newNote: value },
});

export const changeInactiveDriverNote = (driverId, value) => async (
  dispatch
) => {
  await dispatch(inactiveDriverNoteChanged(driverId, value));
};
