import {
  SCORE_HISTORY_FETCHED,
  SCORE_HISTORY_RESETTED,
} from "../actions/scoreHistory";

let filteredScores; // filtering out unnecesarry behaviors
let dateSet;
let week1;
let week2;
let week3;
let week4;
let week5;
let week6;
let weekIndexes;
let weeklySortedScores;
let uniqueWeekIndexes;
let duplicateWeekIndexes;
let dataWithSameWeek;
let mergedData;
let dataWithUniqueWeeks;
// let uniqueBehaviorNames;
// let duplicateBehaviorNames;
// let dataWithSameBehavior;
// let highestComment;
// let filteredBehavior;
// let dataWithUniqueBehaviors;

const getWeek = (date) => {
  // Date.prototype.getWeek = function () {
  //   let date = new Date();
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let weekOne = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to weekOne.
  return (
    1 +
    Math.round(
      ((date.getTime() - weekOne.getTime()) / 86400000 -
        3 +
        ((weekOne.getDay() + 6) % 7)) /
      7
    )
  );
};

export default (state = null, action = {}) => {
  switch (action.type) {
    case SCORE_HISTORY_FETCHED:
      // resetting arrays to empty:
      week1 = [];
      week2 = [];
      week3 = [];
      week4 = [];
      week5 = [];
      week6 = [];

      filteredScores = action.payload
        // removing the "korte ritten", "lange ritten" and "Snelweg" behaviors,
        // only the "totaal" scores are displayed
        // .filter((score) => {
        //   return (
        //     !score.behavior.name.includes("korte") &&
        //     !score.behavior.name.includes("lange") &&
        //     !score.behavior.name.includes("Snelweg")
        //   );
        // })
        // removing the "totaal" from score behavior names
        .map((score) => {
          if (score.behavior.name.includes("totaal")) {
            score.behavior.name = score.behavior.name.split(" ")[0];
            return score;
          } else {
            return score;
          }
        });

      // unique set (turned into an array) of starting dates present in the fetched history (every weekly score starts on a Monday)
      dateSet = [
        ...new Set(
          filteredScores.map((score) => {
            return score.start;
          })
        ),
      ];
      // console.log("unique set of starting dates: ", dateSet);

      // scores sorted into arrays based on starting dates
      filteredScores.forEach((score) => {
        switch (score.start) {
          case dateSet[0]:
            week1.push(score);
            break;
          case dateSet[1]:
            week2.push(score);
            break;
          case dateSet[2]:
            week3.push(score);
            break;
          case dateSet[3]:
            week4.push(score);
            break;
          case dateSet[4]:
            week5.push(score);
            break;
          default:
            week6.push(score);
            break;
        }
      });

      // getting the indexes of the weeks according to the year
      weekIndexes = dateSet.map((date) => {
        return getWeek(new Date(date));
      });

      weeklySortedScores = [
        {
          weekIndex: weekIndexes[0],
          weekScores: week1,
        },
        {
          weekIndex: weekIndexes[1],
          weekScores: week2,
        },
        {
          weekIndex: weekIndexes[2],
          weekScores: week3,
        },
        {
          weekIndex: weekIndexes[3],
          weekScores: week4,
        },
        {
          weekIndex: weekIndexes[4],
          weekScores: week5,
        },
        {
          weekIndex: weekIndexes[5],
          weekScores: week6,
        },
      ];

      // sorting the results based on time order (week index)
      weeklySortedScores.sort((a, b) => a.weekIndex - b.weekIndex);

      // sometimes the history doesn't have 5 weeks
      if (weekIndexes.length < 7) {
        // removes every element after the specified index including the value of the spec. index
        weeklySortedScores.splice(weekIndexes.length);
      }

      // console.log("not sorted", weeklySortedScores)

      // sometimes there are duplicate week indexes (scores starting 2 days later -> still the same week)
      if (weekIndexes.length !== [...new Set(weekIndexes)].length) {
        uniqueWeekIndexes = weeklySortedScores
          .map((week) => {
            return {
              count: 1,
              name: week.weekIndex,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});

        duplicateWeekIndexes = Object.keys(uniqueWeekIndexes).filter(
          (a) => uniqueWeekIndexes[a] > 1
        );

        dataWithUniqueWeeks = weeklySortedScores.filter(
          (week) => !duplicateWeekIndexes.includes(week.weekIndex.toString())
        );

        duplicateWeekIndexes.map((duplicateWeekIndex) => {
          dataWithSameWeek = weeklySortedScores.filter(
            (week) => week.weekIndex === Number(duplicateWeekIndex)
          );
          mergedData = {
            weekIndex: Number(duplicateWeekIndex),
            weekScores: dataWithSameWeek.reduce((accumulator, currentValue) => {
              accumulator.push(...currentValue.weekScores);
              return accumulator;
            }, []),
          };

          dataWithUniqueWeeks.push(mergedData);

          return (weeklySortedScores = dataWithUniqueWeeks);
        });
      }

      // console.log("week index duplicates removal", weeklySortedScores)

       // commenting out duplicate behaviour check code as it is removed from backend
      // filter out the duplicate behaviors (with the same grade and date) and keep the one with the highest comment/latest timestamp value
      // weeklySortedScores.map((week) => {
      //   uniqueBehaviorNames = week.weekScores
      //     .map((behavior) => {
      //       return {
      //         count: 1,
      //         name: behavior.behavior.name,
      //       };
      //     })
      //     .reduce((a, b) => {
      //       a[b.name] = (a[b.name] || 0) + b.count;
      //       return a;
      //     }, {});

      //   duplicateBehaviorNames = Object.keys(uniqueBehaviorNames).filter(
      //     (a) => uniqueBehaviorNames[a] > 1
      //   );

      //   dataWithUniqueBehaviors = week.weekScores.filter(
      //     (behavior) => !duplicateBehaviorNames.includes(behavior.behavior.name)
      //   );

      //   duplicateBehaviorNames.map((behaviorName) => {
      //     dataWithSameBehavior = week.weekScores.filter(
      //       (behavior) => behavior.behavior.name === behaviorName
      //     );

      //     if (dataWithSameBehavior.length > 0) {
      //       if (dataWithSameBehavior[0].timestamp) {
      //         // there's a timestamp field

      //         highestComment = Math.max(
      //           ...dataWithSameBehavior.map((behavior) => {
      //             return new Date(behavior.timestamp);
      //           })
      //         );

      //         filteredBehavior = dataWithSameBehavior.find(
      //           (behavior) =>
      //             new Date(behavior.timestamp).toString() ===
      //             new Date(highestComment).toString()
      //         );
      //       } else if (
      //         dataWithSameBehavior[0].comment.includes("Imported on")
      //       ) {
      //         // no timestamp, and the comment field is in a format e.g. "Imported on Monday, 06 July 2020"

      //         highestComment = Math.max(
      //           ...dataWithSameBehavior.map((behavior) => {
      //             return new Date(behavior.comment);
      //           })
      //         );

      //         filteredBehavior = dataWithSameBehavior.find(
      //           (behavior) =>
      //             new Date(behavior.comment).toString() ===
      //             new Date(highestComment).toString()
      //         );
      //       } else {
      //         // no timestamp, and the comment field is in a format e.g. "233463"
      //         highestComment = Math.max(
      //           ...dataWithSameBehavior.map((behavior) => {
      //             return parseInt(behavior.comment);
      //           })
      //         );

      //         filteredBehavior = dataWithSameBehavior.find(
      //           (behavior) => parseInt(behavior.comment) === highestComment
      //         );
      //       }
      //     }

      //     dataWithUniqueBehaviors.push(filteredBehavior);

      //     return dataWithUniqueBehaviors;
      //   });

      //   return (week.weekScores = dataWithUniqueBehaviors);
      // });

      // console.log("sorted", weeklySortedScores)
      // returning the weekly sorted score history
      return weeklySortedScores;
    case SCORE_HISTORY_RESETTED:
      return null;
    default:
      return state;
  }
};
