import { DashboardAllStatsDataFetched } from "../actions/dashboardGraphs";
// let pieData={};
let graphDataForDashboard = {
  companyData:{},
  allStats:{}
}
export default (state = graphDataForDashboard, action = {}) => {
  switch (action.type) {
    case DashboardAllStatsDataFetched:
      return {
        ...state,
        allStats:{...state.allStats,...action.payload.data},
        companyData:{...state.companyData,...action.payload.company}
      }
    default:
      return state;
  }
};
