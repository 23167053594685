import { TRANSLATION_LOADING_TRUE, TRANSLATION_LOADING_FALSE, TRANSLATION_FETCHED } from "../actions/messages";

let translationOfMessages = {
    messageLoading: false,
    translation: {}
}

export default (state = translationOfMessages, action = {}) => {
    switch (action.type) {
        case TRANSLATION_FETCHED:
            return {
                ...state,
                translation: { ...state.translation, ...action.payload }
            }
        case TRANSLATION_LOADING_TRUE:
            return {
                ...state,
                messageLoading: true
            };
        case TRANSLATION_LOADING_FALSE:
            return {
                ...state,
                messageLoading: false
            };
        default:
            return state;
    }
};
