import { BEHAVIOR_VS_MONTHS_DATA_FETCHED, RESET_BEHAVIOR_VS_MONTHS } from "../actions/scoreOverview";

let behavVsMonthsState={
  loading:false,
  data:[],
  date:{}
}

export default (state = behavVsMonthsState, action = {}) => {
  switch (action.type) {
    case BEHAVIOR_VS_MONTHS_DATA_FETCHED:
      return {...state,
        date:action.payload.date,
        data:action.payload.data
      };
      case RESET_BEHAVIOR_VS_MONTHS:
        return {
          ...behavVsMonthsState
        };
    default:
      return state;
  }
};