import { PublicClientApplication, EventType } from "@azure/msal-browser";
import store from "./services/store";
import { msalConfig } from "./authConfig";

const authProvider = new PublicClientApplication(msalConfig);

// No need to register callbacks for redirect flow, as we aso use @azure/msal-react, not just the browser package.
// => @azure/msal-react will do the redirecting under the hood, automatically by the components/methods.

// Connecting auth with Redux
authProvider.addEventCallback((message) => {
  switch (message.eventType) {
    case EventType.LOGIN_START:
      // loginRedirect is called
      // payload: RedirectRequest
      store.dispatch({ type: "LOGIN_START", payload: message.payload });
      break;
    case EventType.LOGIN_SUCCESS:
      // Successfully logged in
      // payload: AuthenticationResult
      // { accessToken: string; account: AccountInfo | null; authority: string; cloudGraphHostName?: string; expiresOn: Date; extExpiresOn?: Date; familyId?: string; fromCache: boolean; idToken: string; idTokenClaims: object; msGraphHost?: string; scopes: string[]; state?: string; tenantId: string; tokenType: string; uniqueId: string }
      store.dispatch({
        type: "LOGIN_SUCCESS",
        payload: message.payload,
      });
      break;
    case EventType.LOGIN_FAILURE:
      // Error when logging in
      // no payload, but AuthError
      store.dispatch({ type: "LOGIN_FAILURE", payload: message.error });
      break;

    case EventType.ACQUIRE_TOKEN_START:
      // acquireTokenRedirect or acquireTokenSilent is called
      // payload: RedirectRequest or SilentRequest
      store.dispatch({
        type: "ACQUIRE_TOKEN_START",
        payload: message.payload,
      });
      break;
    case EventType.ACQUIRE_TOKEN_SUCCESS:
      // Successfully acquired token from cache or network
      // payload: AuthenticationResult
      store.dispatch({
        type: "ACQUIRE_TOKEN_SUCCESS",
        payload: message.payload,
      });
      break;
    case EventType.ACQUIRE_TOKEN_FAILURE:
      // Error when acquiring token
      // no payload, but AuthError
      store.dispatch({
        type: "ACQUIRE_TOKEN_FAILURE",
        payload: message.payload,
      });
      break;
    case EventType.ACQUIRE_TOKEN_NETWORK_START:
      // Starting acquiring token from network
      // no payload, no error
      store.dispatch({
        type: "ACQUIRE_TOKEN_NETWORK_START",
        payload: message.payload,
      });
      break;

    case EventType.SSO_SILENT_START:
      // SsoSilent API called
      // payload: SsoSilentRequest
      store.dispatch({
        type: "SSO_SILENT_START",
        payload: message.payload,
      });
      break;
    case EventType.SSO_SILENT_SUCCESS:
      // SsoSilent succeeded
      // payload: AuthenticationResult
      store.dispatch({
        type: "SSO_SILENT_SUCCESS",
        payload: message.payload,
      });
      break;
    case EventType.SSO_SILENT_FAILURE:
      // SsoSilent failed
      // no payload, but AuthError
      store.dispatch({
        type: "SSO_SILENT_FAILURE",
        payload: message.payload,
      });
      break;

    case EventType.HANDLE_REDIRECT_START:
      // HandleRedirectPromise called
      // no payload, no error

      store.dispatch({
        type: "HANDLE_REDIRECT_START",
        payload: message.payload,
      });
      break;
    case EventType.HANDLE_REDIRECT_END:
      // HandleRedirectPromise finished
      // no payload, no error
      store.dispatch({
        type: "HANDLE_REDIRECT_END",
        payload: message.payload,
      });
      // authProvider.logoutRedirect();
      break;

    case EventType.LOGOUT_START:
      // Logout called
      // payload: EndSessionRequest
      store.dispatch({
        type: "LOGOUT_START",
        payload: message.payload,
      });
      break;
    case EventType.LOGOUT_END:
      // Logout finished
      // no payload, no error
      store.dispatch({
        type: "LOGOUT_END",
        payload: message.payload,
      });
      break;
    case EventType.LOGOUT_SUCCESS:
      // Logout success
      // payload: EndSessionRequest
      store.dispatch({
        type: "LOGOUT_SUCCESS",
        payload: message.payload,
      });
      break;
    case EventType.LOGOUT_FAILURE:
      // Logout failed
      // no payload, but AuthError
      store.dispatch({
        type: "LOGOUT_FAILURE",
        payload: message.payload,
      });
      break;
    default:
      break;
  }
});

export { authProvider };
