import { COMPANY_FEATURES_FETCHED } from "../actions/features";
let companyFeaturesData = [];


export default (state = null, action = {}) => {
    switch (action.type) {
        case COMPANY_FEATURES_FETCHED:
            companyFeaturesData.push(action.payload)
            return companyFeaturesData;
        default:
            return state;
    }
};