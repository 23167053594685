import request from "superagent";
import { authProvider } from "../../authProvider";
import { oidcBaseURL } from "../constants";
import { getAccessToken } from "../commonFunctions";

let accounts;
// fetching the coach language
export const COACH_LANGUAGE_FETCHED = "COACH_LANGUAGE_FETCHED";

export const COACH_LANGUAGE_UPDATED = "COACH_LANGUAGE_UPDATED";

const coachLanguageFetched = (coachInfo) => ({
  type: COACH_LANGUAGE_FETCHED,
  payload: coachInfo,
});

const coachLanguageUpdated = (newLangCode) => ({
  type: COACH_LANGUAGE_UPDATED,
  payload: newLangCode
});

export const getCoachLanguage = () => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken if null
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(`${oidcBaseURL}/userinfo`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res && res.unauthorized) {
        console.log(`401 error, retry userInfo API, err is ${err}`);
      }
    })
    .then((response) => {
      dispatch(coachLanguageFetched(response.body));
    })
    .catch(console.error);
};

export const updateCoachLanguage =
  (newLangCode) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken if null
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${oidcBaseURL}/UpdateLanguage?langCode=${newLangCode}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res && res.unauthorized) {
          console.log(`401 error, retry userInfo API, err is ${err}`);
        }
      })
      .then(() => {
        dispatch(coachLanguageUpdated(newLangCode));
      })
      .catch(console.error);
  };
