// import request from "superagent";
// import { authProvider } from "../../../authProvider";
// import { baseURL } from "../constants";

// fetching the portal users
export const PORTAL_USERS_FETCHED = "PORTAL_USERS_FETCHED";

const portalUsersFetched = (users) => ({
  type: PORTAL_USERS_FETCHED,
  payload: users,
});

export const loadPortalUsers = () => async (dispatch) => {
  const dummyPortalUsers = [
    {
      accountMail: "default-a@gmail.com",
      role: "Management",
      groups: "All",
      status: "Accepted",
      userId: "1",
    },
    {
      accountMail: "default-b@gmail.com",
      role: "Management",
      groups: "Group A",
      status: "Invited",
      userId: "2",
    },
    {
      accountMail: "default-c@gmail.com",
      role: "Coach",
      groups: "Group A, Group B",
      status: "Accepted",
      userId: "3",
    },
  ]; // will come from API
  await dispatch(portalUsersFetched(dummyPortalUsers));
};

// toggling edit mode for a portal user
export const PORTAL_USER_EDITMODE_TOGGLED = "PORTAL_USER_EDITMODE_TOGGLED";

const portalUserEditToggled = (userId) => ({
  type: PORTAL_USER_EDITMODE_TOGGLED,
  payload: userId,
});

export const togglePortalUserEdit = (userId) => async (dispatch) => {
  await dispatch(portalUserEditToggled(userId));
};

// updating the data of a portal user (will be needed?)
export const PORTAL_USERS_UPDATED = "PORTAL_USERS_UPDATED";

const portalUsersChanged = (userId, data) => ({
  type: PORTAL_USERS_UPDATED,
  payload: { userId: userId, usersData: data },
});

export const changePortalUsers = (userId, data) => async (dispatch) => {
  await dispatch(portalUsersChanged(userId, data));
};

// adding a new portal user
export const PORTAL_USER_CREATED = "PORTAL_USER_CREATED";

const portalUserCreated = (userData) => ({
  type: PORTAL_USER_CREATED,
  payload: userData,
});

export const createPortalUser = (userData) => async (dispatch) => {
  await dispatch(portalUserCreated(userData));
};

// deleting a portal user
export const PORTAL_USER_DELETED = "PORTAL_USER_DELETED";

const portalUserDeleted = (userId) => ({
  type: PORTAL_USER_DELETED,
  payload: userId,
});

export const deletePortalUser = (userId) => async (dispatch) => {
  await dispatch(portalUserDeleted(userId));
};
