import { DashboardPieChartFetched } from "../actions/dashboardGraphs";
// let pieData={};
let graphDataForDashboard = {
  companyData:{},
  scoreDistributionData:{},
}
export default (state = graphDataForDashboard, action = {}) => {
  switch (action.type) {
    case DashboardPieChartFetched:
      return {
        ...state,
        scoreDistributionData:{...state.scoreDistributionData,...action.payload.data},
        companyData:{...state.companyData,...action.payload.company}
      };
    default:
      return state;
  }
};
