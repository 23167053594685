import { MANAGEMENT_WITH_DRIVERS_NAV_CREATED, MNG_NAV_FILTERED, MNG_NAV_RESETTED, } from "../actions/driversForSidebar";
import i18n from "../../../../i18n";

let driversArray = [];
let nameA;
let nameB;
let mngWithDriversNaviState;
let sortedByZScore;
const savedState = [];
let loginInfoStatusInReducer = false;

export default (state = null, action = {}) => {
  switch (action.type) {
    case MANAGEMENT_WITH_DRIVERS_NAV_CREATED:
      if (action.payload.driverData.length > 0) {
        driversArray.push(
          action.payload.driverData.map((driver) => {
            if (driver.company && driver.latestWeekScore && driver.status) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                weekscore: driver.latestWeekScore.grade,
                statusObject: driver.status,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company && driver.latestWeekScore) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: driver.latestWeekScore.grade,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company && driver.status) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: -1,
                statusObject: driver.status,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: -1,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.companyId) {
              // no drivers
              return {
                company: driver.companyId,
                fullCompanyName: driver.companyId,
                group: null,
                parentGroup: null,
              };
            } else {
              // no info
              return state;
            }
          })
        );

        // sort data to have "overview tab" on top always
        driversArray.sort(function (a, b) {
          nameA = a[0].company.toUpperCase();
          nameB = b[0].company.toUpperCase();
          if (nameA.includes(" OVERVIEW") && !nameB.includes(" OVERVIEW")) {
            return -1;
          }
          if (!nameA.includes(" OVERVIEW") && nameB.includes(" OVERVIEW")) {
            return 1;
          }
          // if the names are equal, don't sort
          return 0;
        });


        sortedByZScore = driversArray.map((companyArray) => {
          const driversWithGrade = companyArray.filter((driver) => { return driver.weekscore !== -1 });
          const driversWithoutGrade = companyArray.filter((driver) => { return driver.weekscore === -1 });
          const driversWithGradeSorted = driversWithGrade.sort((a, b) => a.weekscore - b.weekscore)
          return [...driversWithGradeSorted, ...driversWithoutGrade];
        }
        )

        // //sort drivers list to show driver with blue dot on top
        // sortedByZScore.map((comp) => {
        //   comp.sort((a, b) => {
        //     if (a.statusObject && b.statusObject) {
        //       return b.statusObject.unread - a.statusObject.unread;
        //     }
        //   });
        // });
      }
      if (action.payload.isC2cLoginInfoRequested) {
        loginInfoStatusInReducer = true;
      }
      mngWithDriversNaviState = createSidebarDataset(sortedByZScore, action.payload);

      return mngWithDriversNaviState;
    case MNG_NAV_FILTERED:
      savedState.push(state);
      if (action.payload) {
        let searchResult = state.items[1].children
          .map((company) => {
            let filteredDrivers = company.children.filter((driver) => {
              return driver.name
                .toLowerCase()
                .includes(action.payload.toLowerCase());
            });
            if (filteredDrivers.length > 0) {
              company.children = filteredDrivers;
            } else {
              company.children = [];
            }
            return company;
          })
          .filter((company) => {
            return company.children.length > 0;
          });

        state.items[1].children = searchResult;
        return { ...state };
      } else {
        return { ...state };
      }
    case MNG_NAV_RESETTED:
      mngWithDriversNaviState = createSidebarDataset(sortedByZScore, action.payload);
      // console.log("login in reducer", loginInfoStatusInReducer)
      // compareStatesToPersistChanges(
      //   mngWithDriversNaviState.items[1].children,
      //   action.payload.currentState.items[1].children
      // );
      return mngWithDriversNaviState;
    default:
      return state;
  }
}

// checks which dots are needed for each driver
export const dotCheck = (statusObject) => {
  let numberOfStatuses = 0;
  Object.entries(statusObject).map(([key, value]) => {
    //login/loggedInDriver status is differently treated than other statuses: We want to filter out drivers who have loggedInDriver status as FALSE whwreas we filter drivers who have other status as TRUE
    if (key !== "unOpened" && value) {
      numberOfStatuses++;
    }
  });
  if (numberOfStatuses > 1) {
    return `fa fa-exclamation-circle sidebar-nav-icon--red`;
  } else {
    if (
      statusObject.unOpened &&
      !statusObject.unRead &&
      !statusObject.overDue &&
      !statusObject.postponed &&
      !statusObject.newDriver &&
      !statusObject.login
    ) {
      return "fa fa-question-circle-o sidebar-nav-icon--gray";
    }
    if (statusObject.postponed) {
      return "fa fa-pause-circle-o sidebar-nav-icon--orange";
    }
    if (statusObject.unRead) {
      return "fa fa-commenting-o fa-solid sidebar-nav-icon--blue";
    }
    if (statusObject.overDue) {
      return "fa fa-clock-o fa-solid sidebar-nav-icon--red";
    }
    if (statusObject.newDriver) {
      return "fa fa-graduation-cap sidebar-nav-icon--green";
    }
    if (statusObject.login) {
      return "fa fa-mobile sidebar-nav-icon--black";
    }
  }
};

//Create dataset for sidebar
export const createSidebarDataset = (drivers, action) => {
  return {
    items: [
      {
        title: true,
        name: "Management",
      },
      {
        name: i18n.t("translation:Company.5"),
        url: "", // if the url is empty string, the dropdown loads as open by default. if it has a path or "#", it will load as closed
        icon: "icon-speech",
        attributes: {
          id: "first_nav_toggle",
        },
        children: drivers.map((company) => {
          // console.log("DRIVERSSS ARRAY OF ARRAYS", drivers)
          let companyNameNew;
          let companyUrlNew;
          let currentData = mngWithDriversNaviState?.items[1].children;
          let currenDataHaveCompany = currentData?.find((companyName) => companyName?.name?.includes(company[0].fullCompanyName));
          if (currenDataHaveCompany) {
            companyNameNew = currenDataHaveCompany?.name;
            companyUrlNew = currenDataHaveCompany?.url;
          }
          else {
            companyNameNew = action.group !== null && company[0].parentGroup !== null ? company[0].company + " " + company[0].group : company[0].company;
            companyUrlNew = action.group !== null && company[0].parentGroup !== null ? `/management/${company[0].company}:${company[0].group}` : `/management/${company[0].company}`
          }

          //old implementation for groups
          // const companyName = action.group !== null && company[0].parentGroup!== null ? company[0].company + " " + company[0].group : company[0].company;
          // const companyUrl = action.group !== null && company[0].parentGroup !== null ? `/coaching/${company[0].company}:${company[0].group}` : `/coaching/${company[0].company}`

          return {
            name: companyNameNew,
            url: companyUrlNew,
            icon: "icon-briefcase",
            attributes: {
              onClick: () => {
                return;
              },
              id: companyNameNew,
            },
            badge: {
              variant: "info",
              text: ``,
            },
            children: company[0].name
              ? company.map((driver) => {
                //driver.group && console.log(`/coaching/${company[0].company.toString().toLowerCase()}:${driver.group.toString().toLowerCase()}`);
                const driverUrl = driver.parentGroup !== null ? `/management/${company[0].company.toLowerCase()}:${driver.group.toLowerCase()}/${driver.driverId
                  }` : `/management/${company[0].company.toString().toLowerCase()}/${driver.driverId
                  }`
                return {
                  name: "  " + driver.name,
                  url: driverUrl, /*`/coaching/${company[0].company.toString().toLowerCase()}/${driver.driverId
                      }`,*/
                  //If a mentor/ext coach is logged in(coach-to-coach portal), do not show blue/orange/grey icons
                  icon: driver.statusObject
                    ? loginInfoStatusInReducer
                      ? dotCheck({
                        // passing only login status of the driver if mentor/manager wants to see the login.
                        postponed: false,
                        unOpened: false,
                        feedback: false,
                        unRead: false,
                        overDue: false,
                        newDriver: false,
                        login: driver.statusObject.loggedInDriver,
                      })
                      : ""
                    : "",
                  info: driver.statusObject
                    ? {
                      postponed: false,
                      unOpened: false,
                      feedback: false,
                      unRead: false,
                      overDue: false,
                      newDriver: false,
                      login: false,
                    }
                    : {
                      postponed: false,
                      unOpened: false,
                      feedback: false,
                      unRead: false,
                      overDue: false,
                      newDriver: false,
                      login: false,
                    },
                  // icon: "icon-briefcase",
                  attributes: {
                    id: driver.driverId,
                    onClick: () => {
                      // if the child is clicked, the company dropdown won't close
                      setTimeout(() => {
                        document.getElementById(
                          companyNameNew
                        ).parentElement.className =
                          "nav-item nav-dropdown open";
                      }, 300);

                      // // if the child is clicked, its unOpened and unRead icons should be set to false
                      // action.dispatch(
                      //   resetIcons(
                      //     companyNameNew.toString().toLowerCase(),
                      //     driver.driverId
                      //   )
                      // );
                    },
                  },
                  badge: {
                    variant: "transparent",
                    // class: iconCheck(child),
                    class: "nav-badge",
                    text:
                      driver.weekscore === -1
                        ? " "
                        : (
                          Math.round((driver.weekscore / 10) * 10) / 10
                        ).toFixed(1),
                  },
                };
              })
              : [],
          };
        }),
      },
    ],
  };
};

export const compareStatesToPersistChanges = (nextState, prevState) => {
  prevState.map((compPrev) => {
    nextState.map((compNext) => {
      if (compPrev.name === compNext.name) {
        compPrev.children.map((driverPrev) => {
          compNext.children.map((driverNext) => {
            if (driverPrev.name === driverNext.name) {
              driverNext.info = {
                ...driverPrev.info,
              };
              driverNext.icon = dotCheck({
                postponed: driverNext.info.postponed,
                unOpened: driverNext.info.unOpened,
                feedback: driverNext.info.feedback,
                unRead: driverNext.info.unRead,
                overDue: driverNext.info.overDue,
                newDriver: driverNext.info.newDriver,
                login: driverNext.info.login,
              });
            }
          });
        });
        //When sidebar is reset, drivers with unread status do not slide up hence sort those company drivers again
        // compNext.children.sort((a, b) => {
        //   return b.info.unRead - a.info.unRead;
        // });
      }
    });
  });
};