import {
  SDISSUES_FETCHED,
  SDISSUES_RESETTED,
  ISSUE_CREATE_SUCCESS
} from "../actions/serviceDeskIssue";

let SDIssuesOfDiver;
let extendedIssues;
let newIssue;
let sortedComments;
export default (state = null, action = {}) => {
  switch (action.type) {
    case SDISSUES_FETCHED:
      SDIssuesOfDiver = action.payload?.issues?.length > 0 ? action.payload?.issues.map((issue) => {
        return {
          creationDate: new Date(issue?.issue?.createdAt),
          title: issue?.issue?.title,
          description: getDescription(issue?.issue?.description),
          status: issue?.issue?.state,
          comments: issue?.comments,
        }
      }) : []
      sortedComments = SDIssuesOfDiver?.map((issue) => {
        if (issue?.comments.length > 0) {
          const sortComments = issue.comments.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
          return {
            ...issue,
            comments: [...sortComments]
          }
        } else {
          return { ...issue }
        }
      })
      return { issues: [...sortedComments], driverId: action?.payload?.driverId };
    case SDISSUES_RESETTED:
      return null;
    case ISSUE_CREATE_SUCCESS:
      newIssue = {
        creationDate: new Date(),
        title: action.payload?.issue?.title,
        description: getDescription(action.payload?.issue?.description),
        status: action.payload?.issue?.state,
        comments: []
      }
      extendedIssues = [...state.issues, newIssue];
      return { ...state, issues: [...extendedIssues] };
    default:
      return state;
  }
};


const getDescription = (givenString) => {

  if (givenString) {
    const descriptionArray = givenString.split('\n');
    const lastLine = descriptionArray[descriptionArray.length - 1];

    return lastLine;
  }

  else {
    return ""
  }
}