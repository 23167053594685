import { GROUP_SETTINGS_FETCHED } from "../actions/fetchGroupSettings";


let initialState = {
    groupSettings:[]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GROUP_SETTINGS_FETCHED:
            return {
                ...state,
                groupSettings:[...state.groupSettings,action.payload]
            }
        default:
            return {...state}
    }
}

