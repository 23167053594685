import {
  DRIVERS_REQUESTED_PRIZES_TABLE_FETCHED,
  PRIZE_RECEIVED,
  PRIZE_RECEIVED_CANCELED,
  //  DRIVER_REQUESTED_PRIZES_UPDATED
} from "../actions/driversRequestedPrizesTable";

let driversRequestedPrizesTable = [];
let afterPrizeReceived = [];
let afterPrizeReceivedCanceled = [];
let objIndex;

export default (state = null, action = {}) => {
  switch (action.type) {
    case DRIVERS_REQUESTED_PRIZES_TABLE_FETCHED:
      driversRequestedPrizesTable = action.payload.map((prizesRequested) => {
        return {
          id: prizesRequested.prizeTransaction.prizeTransactionId,
          driverId: prizesRequested.driver.driverId,
          firstName: prizesRequested.driver.personalDetails.givenName,
          lastName: prizesRequested.driver.personalDetails.surname,
          prizeId: prizesRequested.prizeTransaction.prize.prizesId,
          name: prizesRequested.prizeTransaction.prize.name,
          dateRequested: prizesRequested.prizeTransaction.dateRequested,
          dateReceived: prizesRequested.prizeTransaction.dateReceived,
          dateCanceled: prizesRequested.prizeTransaction.dateCanceled,
          prizeTransactionId:
            prizesRequested.prizeTransaction.prizeTransactionId,
          received: Boolean(prizesRequested.prizeTransaction.dateReceived),
          cancelled: Boolean(prizesRequested.prizeTransaction.dateCanceled),
        };
      });
      return driversRequestedPrizesTable;
    case PRIZE_RECEIVED:
      afterPrizeReceived = state;
      objIndex = afterPrizeReceived.findIndex(
        (obj) => obj.id === action.payload
      );
      afterPrizeReceived[objIndex].received = true;
      afterPrizeReceived[objIndex].dateReceived = new Date().toISOString();
      // console.log(objIndex,afterPrizeReceived);

      return [...afterPrizeReceived];
    case PRIZE_RECEIVED_CANCELED:
      afterPrizeReceivedCanceled = state.filter(
        (prizesRequested) =>
          prizesRequested.prizeTransactionId !== action.payload
      );

      return afterPrizeReceivedCanceled;

    default:
      return state;
  }
};
