import {
  LAST_LOGIN_DATE_FETCHED,
  LAST_LOGIN_DATE_RESETTED,
} from "../actions/loggedInDate";

let convertedDatetoWeek;
let dateLabel;
let dateObject;

export default (state = null, action = {}) => {
  switch (action.type) {
    case LAST_LOGIN_DATE_FETCHED:
      if (action.payload && action.payload.length > 0) {
        convertedDatetoWeek = getWeek(new Date(action.payload));
        dateObject=new Date(action.payload);
        // dateLabel=convertedDatetoWeek>=1?`${dateObject.getDate()}/${dateObject.getMonth() + 1} (Week ${convertedDatetoWeek})`:`${dateObject.getDate()}/${dateObject.getMonth() + 1}`

        dateLabel=convertedDatetoWeek>=1?`${dateObject.getDate()}/${dateObject.getMonth() + 1} (Week ${convertedDatetoWeek})`:`NotLoggedIn`
        return {weekNum:convertedDatetoWeek,date:dateLabel};
      } else {
        return "No data.";
      }
    case LAST_LOGIN_DATE_RESETTED:
      return null;
    default:
      return state;
  }
};

const getWeek = (date) => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let weekOne = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to weekOne.
  return (
    1 +
    Math.round(
      ((date.getTime() - weekOne.getTime()) / 86400000 -
        3 +
        ((weekOne.getDay() + 6) % 7)) /
        7
    )
  );
};
