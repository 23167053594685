
import { TEMPLATES_FETCHED } from "../actions/messages";

const listOfActiveTemplateIds = [
  "1d8cf9d9-832e-454e-991b-aa711b089cd7",
  "16731eae-4936-4100-b3f5-e0e5ef3cdf19",
  "ed46b871-feb7-48a1-86fb-3e37bb69ade3",
  "a17f7d2a-04e0-4668-a67b-1ad38f13e9f2",
  // "940ef8b2-a96a-465a-9f6e-bcb68070a482",
  // "f597a286-419a-41b3-a504-aa9b7a23c415",
  "aaefe780-0adc-4dfe-9c52-54f6cec2c928",
  "1c8baa2d-5085-44b8-a3d1-8aea10acf7bf",
  "1a5190b8-6b28-4791-875f-fc585cfc88c8",
  "4cba805d-5d16-43d5-baae-78a2a880bc67",
  "d1580802-dc65-4d8b-89ac-9249a84088a2",
  "d51d9284-0278-4439-951f-537282795be3",
  "8539c391-04c7-4d0f-8d14-4de25445e778",
  "3231347b-7f4b-4bc2-af07-ec64bba7e5e6",
  "97cfa7fd-7294-49cf-bc5f-179c726c5a8f",
  "be0d3ab3-4c5b-4ac2-b642-54bb200ad9e1",
  "cdf4527e-f092-4d06-a6e6-f0ce5b42888a",
  "eafa4cd9-8d21-422a-bf02-0621a5f68d21",
  "003fedee-91e7-432b-ac9d-efbffd8dfbf2",
  "4aead9ed-b1fe-4ce9-984a-58766ea693aa",
  "893503f1-aa84-473b-b1c4-169becf792f8",
  "b44bd0e8-86b7-4e1f-94b3-d83c3646113e",
  "974e9df0-7452-4b21-92ca-1d683e783f66",
  "25f3d5ee-888d-41fe-ae5a-df0639a73be9",
  "a5a1fd55-23cf-4432-ae9b-1a835914a0fb",
  "df3bcc0d-5dc0-441f-96fd-34d7ff639659",
  "6dac516a-c1a2-4af6-afe2-d07023e60097",
  "0106b997-27cd-4d18-9b38-14b90f96134d",
  "15cd7f98-86c0-4203-8147-58f65c1539f0",
  "d9682159-1096-41a8-93cb-a58317f8ab53",
  "a49c39df-c0d4-4957-847d-f72c34955905",
  "740cc639-7fe1-458b-81ee-1ec239149120",
  "1773a271-31f3-4876-86bf-aa36291585c8",
  "276a8ef3-c5ce-49f8-a73a-61958775362b",
  //You can get these template ids from GetTemplates api response
  // don't forget to add translations to the newly added cat/template names! (public/locales)
];

//seperate template set for spanish coach
const listOfSpanishTemplates = [
  "eabdcdd8-e3c4-407a-aa22-7f7d29616aac", // onboarding support installation spanish
  "0622164a-a7f2-438d-b439-827b39a9615e", //onboarding support login
  "e54753bd-6031-428a-b097-dc116f0d9d1e", //improvement spanish
  "d6312368-e6c8-417d-a567-c5232d7cd960", //coach message generic spanish
  "d186f9cc-04cb-4f5c-b1a3-288cd0538b10", //performance review top tip spanish
  "cb2f074d-fbee-483e-b14b-16d4e7d0cb3f", //introduction 1 spanish
  "7998d7df-fc69-43ab-980f-208935a27ae9", //transfer chat spanish
  "6a7ba612-7418-4603-b5c0-03fdf4a42c82", //advice request spanish
  "2f64fa3f-4945-4aea-b313-379a499c532b", //performance review improvement spanish
  "194b039b-4302-455c-b2fb-945133b31939", //response question
]

let onlyActiveTemplates = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case TEMPLATES_FETCHED:
      if (action.payload?.isSpanishCoach) {
        onlyActiveTemplates = action.payload?.templates.filter((template) =>
          listOfSpanishTemplates.includes(template.id))
        
        //filtering out invalid contents for languages other thatn en and es
        onlyActiveTemplates = onlyActiveTemplates?.map((template) => {
          return {
            ...template,
            languages:Object.keys(template.languages).filter(key =>
              key !== 'nl' && key !=='ro' && key !=='de' && key !=='fr').reduce((obj, key) =>
              {
                  obj[key] = template.languages[key];
                  return obj;
              }, {}
          )
          }
        })
      }
      else {
        onlyActiveTemplates = action.payload?.templates.filter((template) =>
        listOfActiveTemplateIds.includes(template.id)
        );

        //filter out es_es content from other templates as it doesn't have content
        onlyActiveTemplates = onlyActiveTemplates?.map((template) =>  ({
          ...template,
          languages: Object.keys(template.languages).filter(key =>
            key !== 'es_ES').reduce((obj, key) =>
            {
                obj[key] = template.languages[key];
                return obj;
            }, {}
        )
        }))
      } 

      // console.log("Active templates", onlyActiveTemplates);
      return onlyActiveTemplates;
    default:
      return state;
  }
};
