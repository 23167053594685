import React, { useState } from "react";
import { ButtonToolbar, ButtonGroup, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "./Utils/hooks";

import { updateCoachLanguage } from "../../services/commonActions/coach_language";

// updating sidebar navigation language
export const LANG_CHANGE = "LANG_CHANGE";
/**
 * Creates a functional component containing the language switcher buttons.
 * @param {any} props The props passed for this functional component.
 * @returns {any} Returns a functional component.
 */
export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const coachInfo = useSelector((state) => state.coachInfo);
  const [radioSelected, setRadioSelected] = useState(coachInfo && coachInfo.lang ? coachInfo.lang : "nl");
  //console.log(radioSelected);

  const languageChanged = (radioSelected) => ({
    type: LANG_CHANGE,
    payload: radioSelected,
  });

  React.useEffect(() => {
    if (coachInfo && coachInfo.lang && coachInfo.lang === "nl-NL") {
      i18n.changeLanguage("nl");
      setRadioSelected("nl")
    } else if (coachInfo && coachInfo.lang) {
      i18n.changeLanguage(coachInfo.lang);
      setRadioSelected(coachInfo.lang)
    }
    else {
      i18n.changeLanguage("nl");
    }
  }, [coachInfo]);

  const dispatch = useDispatch();

  const onRadioBtnClick = (radioSelected) => {
    setRadioSelected(radioSelected);
    i18n.changeLanguage(radioSelected);
    dispatch(languageChanged(radioSelected));
    dispatch(updateCoachLanguage(radioSelected == "nl" ? "nl-NL" : "en"));
  };

  return (
    <ButtonToolbar aria-label="Toolbar with button groups" className="mr-5">
      <ButtonGroup aria-label="First group" size="sm" className="shadow-sm">
        <Button
          color="outline-secondary"
          onClick={() => onRadioBtnClick("nl")}
          //active={radioSelected === "nl"}
          active={radioSelected && radioSelected.includes("nl")}
        >
          <i className="flag-icon flag-icon-nl h5 mb-0" title="nl" id="nl"></i>
        </Button>
        <Button
          color="outline-secondary"
          onClick={() => onRadioBtnClick("en")}
          //active={radioSelected === "en"}
          active={radioSelected && radioSelected.includes("en")}
        >
          <i className="flag-icon flag-icon-gb h5 mb-0" title="gb" id="gb"></i>
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
}
