import {
  PRIZES_TABLE_FETCHED,
  NEW_PRIZE_ADDED,
  PRIZE_REMOVED,
  PRIZE_EDITED,
} from "../actions/driverPrizesTable";

let newPrize = {};
let editedTablePrizes = [];
let editPrizeIndex;

let prizesTableWithEditmodeOption = [];
let afterPrizeDeleted = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case PRIZES_TABLE_FETCHED:
      prizesTableWithEditmodeOption = action.payload.map((prize) => {
        return {
          active: prize.active,
          PrizeImage: prize.image,
          prizeId: prize.prizesId,
          name: prize.name,
          info: prize.info,
          quantity: prize.quantity,
          difficulty: prize.difficulty,
          groupPrize: prize.isGroupPrize,
          group: prize.companyGroupId,
          groupName: prize.companyGroup?.name,
          companyId: prize.companyId
        };
      });
      return prizesTableWithEditmodeOption;

    case NEW_PRIZE_ADDED:
      newPrize = {
        ...action.payload,
        // editMode: false,
      };
      return [...state, newPrize];
    case PRIZE_REMOVED:
      afterPrizeDeleted = state.filter(
        (prize) => prize.prizeId !== action.payload
      );
      return afterPrizeDeleted;
    case PRIZE_EDITED:
      editedTablePrizes = [...state];
      editPrizeIndex = state.findIndex(
        (obj) => obj.prizeId === action.payload.prizeId
      );
      editedTablePrizes[editPrizeIndex] = action.payload;
      return editedTablePrizes;
    default:
      return state;
  }
};
