import {
  INACTIVE_DRIVERS_TABLE_FETCHED,
  INACTIVE_DRIVER_CONTACTED,
  INACTIVE_SORTING_CHANGED,
  INACTIVE_DRIVER_NOTE_UPDATED,
} from "../actions/inactiveDriversTable";

let updatedTableContacted = [];
let driverToBeEdited = {};
let sortedByNameTable = [];
let updatedTableNote = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case INACTIVE_DRIVERS_TABLE_FETCHED:
      // sorting by first name by default
      console.log("Console LOG: Inactive drivers");
      sortedByNameTable = sortByName(action.payload, "firstName");
      return sortedByNameTable;
    case INACTIVE_DRIVER_CONTACTED:
      updatedTableContacted = state.map((driver) => {
        if (driver.driverId === action.payload.driverId) {
          driverToBeEdited = {
            ...driver,
            contacted: action.payload.contactValue,
            contactedDate: action.payload.contactValue ? new Date() : null,
          };
          return driverToBeEdited;
        }
        return driver;
      });
      return updatedTableContacted;
    case INACTIVE_SORTING_CHANGED:
      sortedByNameTable = sortByName(state, action.payload);
      return [...sortedByNameTable];
    case INACTIVE_DRIVER_NOTE_UPDATED:
      updatedTableNote = state.map((driver) => {
        if (driver.driverId === action.payload.driverId) {
          driverToBeEdited = {
            ...driver,
            hasNote: action.payload.newNote.length > 0 ? true : false,
            note:
              action.payload.newNote.length > 0 ? action.payload.newNote : null,
          };
          return driverToBeEdited;
        }
        return driver;
      });
      return updatedTableNote;
    default:
      return state;
  }
};

const sortByName = (arrayToBeSorted, sortProperty) => {
  if (sortProperty === "companyId") {
    const result = arrayToBeSorted.sort((a, b) => {
      return a[sortProperty] - b[sortProperty];
    });
    return result;
  } else {
    const result = arrayToBeSorted.sort((a, b) => {
      let nameA = a[sortProperty].toUpperCase(); // regardless of capital letters
      let nameB = b[sortProperty].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0; // names are the same
    });
    return result;
  }
};
