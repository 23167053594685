import { GRAPHS_FETCHED } from "../actions/dashboardGraphs";
import { DashboardPieChartFetched, DashboardAllScoresDataFetched, DashboardAllStatsDataFetched } from "../actions/dashboardGraphs";
// let pieData={};
let graphDataForDashboard = {
  // companyData:{},
  scoreDistributionData:{},
  allScores: {},
  // minGoalSettings: {},
  allStats:{}
}
export default (state = graphDataForDashboard, action = {}) => {
  switch (action.type) {
    case GRAPHS_FETCHED:
      return action.payload;
    case DashboardPieChartFetched:
      // pieData = {...state.graphs.pieData,...action.payload }
      // console.log("in reducer", pieData)
      return {
        ...state,
        scoreDistributionData:{...state.scoreDistributionData,...action.payload}
      };
    case DashboardAllScoresDataFetched:
      return {
        ...state,
        allScores: { ...state.allScores, ...action.payload }
      };
    // case DashboardMinGoalSettingsDataFetched:
    //   return {
    //     ...state,
    //     minGoalSettings: { ...state.minGoalSettings, ...action.payload.data },
    //     companyData:{...state.companyData,...action.payload.company}
    //   };
    case DashboardAllStatsDataFetched:
      return {
        ...state,
        allStats:{...state.allStats,...action.payload}
      }
    default:
      return state;
  }
};
