import {
  OUTLIER_SCORES_FETCHED,
  OUTLIER_SCORES_RESETTED,
} from "../actions/outlierScores";

let filteredScores;
let includesLowest;
let lowestKpi;
// let behaviorNames;
// let reducedScores;

export default (state = null, action = {}) => {
  switch (action.type) {
    case OUTLIER_SCORES_FETCHED:
      if (action.payload === []) {
        return [];
      }
      filteredScores = action.payload
        // removing the "korte ritten", "lange ritten" and "Snelweg" behaviors,
        // only the "totaal" scores are displayed
        // .filter((score) => {
        //   return (
        //     !score.behavior.name.includes("korte") &&
        //     !score.behavior.name.includes("lange") &&
        //     !score.behavior.name.includes("Snelweg")
        //   );
        // })
        // removing the "totaal" from score behavior names
        .map((score) => {
          if (score.behavior.name.includes("totaal")) {
            score.behavior.name = score.behavior.name.split(" ")[0];
            return score;
          } else {
            return score;
          }
        })
        // removing behaviors that doesn't have a valid zScore value
        .filter((score) => {
          return score.zScore && typeof score.zScore === "number";
        });

      // finding the kpi with the lowest zscore and adding isLowest parameters to each kpi
      includesLowest = filteredScores.map((score) => {
        lowestKpi = filteredScores.reduce(
          (min, current) => (current.zScore < min.zScore ? current : min),
          filteredScores[0]
        );

        if (score.behavior.behaviorId === lowestKpi.behavior.behaviorId) {
          return {
            ...score,
            isLowest: true,
          };
        }
        return {
          ...score,
          isLowest: false,
        };
      });

      // removing duplicate scores
      // behaviorNames = [
      //   ...new Set(filteredScores.map((score) => score.behavior.name)),
      // ];
      // reducedScores = behaviorNames.map((name) => {
      //   return filteredScores.find((score) => score.behavior.name === name);
      // });

      //   console.log("filtered outlier result from reducer: ", reducedScores);
      return includesLowest;
    case OUTLIER_SCORES_RESETTED:
      return null;
    default:
      return state;
  }
};
