import { COMPANY_YEARLY_INFRIGMENTS_FETCHED, 
    DRIVERS_YEARLY_INFRIGMENTS_FETCHED, 
    COMPANY_MONTHLY_INFRIGMENTS_FETCHED, 
    DRIVERS_MONTHLY_INFRIGMENTS_FETCHED,
    MONTHLY_INFRIGMENTS_FETCHED, 
    COMPANY_MONTHLY_INFRIGMENTS_WITH_NAMES_FETCHED,
    RESET_TO_INITIAL_STATE
 } from "../actions/tachoManagementApis";

const initialState={
    companyId:"",
    data:[],
    monthlyCompanyData: [],
    infrigmentData: [],
    driversData: []
}
export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_YEARLY_INFRIGMENTS_FETCHED:
            return {
                companyId:action.payload.companyId,
                data:action.payload.features
            };
         case DRIVERS_YEARLY_INFRIGMENTS_FETCHED:
            return {
                driversData:action.payload.features
            };
        case COMPANY_MONTHLY_INFRIGMENTS_FETCHED:
            return {
                companyId:action.payload.companyId,
                data:action.payload.features
            };
        case DRIVERS_MONTHLY_INFRIGMENTS_FETCHED:
            return {
                ...initialState,
                data:action.payload.features
            };
        case MONTHLY_INFRIGMENTS_FETCHED:
            return {
                infrigmentData:action.payload.features
            };
        case COMPANY_MONTHLY_INFRIGMENTS_WITH_NAMES_FETCHED:
            return {
                monthlyCompanyData:action.payload.features
            };
        case RESET_TO_INITIAL_STATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}