import { loginRequest } from "../authConfig";
import store from "./store";

/**
 * Returns the current access token in order to authenticate the API call.
 * If the acquireTokenSilent call attempts a refresh token call and the refresh token is expired,
 * MSAL will attempt to make a silent request in an iframe for a new authorization code.
 * @param {any} accounts The logged in accounts, if any.
 * @param {any} authProvider The current auth porivder, the PublicClientApplication instance
 * @returns {any} Returns an accessToken.
 */
export async function getAccessToken(accounts, authProvider) {
  if (accounts.length > 0) {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    const accessToken = await authProvider
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken;
      })
      .catch((error) => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        console.log(
          `Error while silent token gathering: ${error}, initiating login`
        );
        store.dispatch({
          type: "REFRESH_TOKEN_FAILURE",
          payload: null,
        });
        return null;
      });
    //   .catch(error => {
    //     // acquireTokenSilent can fail for a number of reasons, fallback to interaction
    //     if (error instanceof InteractionRequiredAuthError) {
    //         instance.acquireTokenPopup(request).then(response => {
    //             setAccessToken(response.accessToken);
    //         });
    //     }
    // })

    return accessToken;
  }

  return null;
}
