import { SCORES_FETCHED, SCORES_RESETTED } from "../actions/scores";

let filteredScores;
// let uniqueBehaviorNames;
// let duplicateBehaviorNames;
// let dataWithUniqueBehaviors;
// let dataWithSameBehavior;
// let highestComment;
// let filteredBehavior;

export default (state = null, action = {}) => {
  switch (action.type) {
    case SCORES_FETCHED:
      filteredScores = action.payload
        // removing the "korte ritten", "lange ritten", "Snelweg", "Provinciaal", "Woonerf" and "Stedelijk" behaviors,
        // only the "totaal" scores are displayed
        .filter((score) => {
          return (
            // !score.behavior.name.includes("korte") &&
            // !score.behavior.name.includes("lange") &&
            // !score.behavior.name.includes("Snelweg") &&
            !score.behavior.name.includes("Provinciaal") &&
            !score.behavior.name.includes("Woonerf") &&
            !score.behavior.name.includes("Stedelijk")
          );
        })
        // removing the "totaal" from score behavior names that are going to be displayed
        .map((score) => {
          if (score.behavior.name.includes("totaal")) {
            score.behavior.name = score.behavior.name.split(" ")[0];
            return score;
          } else {
            return score;
          }
        });

      // console.log("without sorting duplicates: ", filteredScores);

      //commenting duplicate check code
      // filter out the duplicate behaviors (with the same name) and keep the one with the highest comment/latest timeStamp value
      // uniqueBehaviorNames = filteredScores
      //   .map((behavior) => {
      //     return {
      //       count: 1,
      //       name: behavior.behavior.name,
      //     };
      //   })
      //   .reduce((a, b) => {
      //     a[b.name] = (a[b.name] || 0) + b.count;
      //     return a;
      //   }, {});

      // duplicateBehaviorNames = Object.keys(uniqueBehaviorNames).filter(
      //   (a) => uniqueBehaviorNames[a] > 1
      // );

      // dataWithUniqueBehaviors = filteredScores.filter(
      //   (behavior) => !duplicateBehaviorNames.includes(behavior.behavior.name)
      // );

      // duplicateBehaviorNames.map((behaviorName) => {
      //   dataWithSameBehavior = filteredScores.filter(
      //     (behavior) => behavior.behavior.name === behaviorName
      //   );

      //   if (dataWithSameBehavior.length > 0) {
      //     if (dataWithSameBehavior[0].timeStamp) {
      //       // there's a timeStamp field

      //       highestComment = Math.max(
      //         ...dataWithSameBehavior.map((behavior) => {
      //           return new Date(behavior.timeStamp);
      //         })
      //       );

      //       filteredBehavior = dataWithSameBehavior.find(
      //         (behavior) =>
      //           new Date(behavior.timeStamp).toString() ===
      //           new Date(highestComment).toString()
      //       );
      //     } else if (dataWithSameBehavior[0].comment.includes("Imported on")) {
      //       // no timeStamp, and the comment field is in a format e.g. "Imported on Monday, 06 July 2020"

      //       highestComment = Math.max(
      //         ...dataWithSameBehavior.map((behavior) => {
      //           return new Date(behavior.comment);
      //         })
      //       );

      //       filteredBehavior = dataWithSameBehavior.find(
      //         (behavior) =>
      //           new Date(behavior.comment).toString() ===
      //           new Date(highestComment).toString()
      //       );
      //     } else {
      //       // no timeStamp, and the comment field is in a format e.g. "233463"
      //       highestComment = Math.max(
      //         ...dataWithSameBehavior.map((behavior) => {
      //           return parseInt(behavior.comment);
      //         })
      //       );

      //       filteredBehavior = dataWithSameBehavior.find(
      //         (behavior) => parseInt(behavior.comment) === highestComment
      //       );
      //     }
      //   }

      //   dataWithUniqueBehaviors.push(filteredBehavior);

      //   return dataWithUniqueBehaviors;
      // });

      // console.log("dataWithUniqueBehaviors and filteredScores", dataWithUniqueBehaviors,filteredScores);
      // return dataWithUniqueBehaviors;
      return filteredScores;

    // removing duplicate scores
    // behaviorNames = [
    //   ...new Set(filteredScores.map((score) => score.behavior.name)),
    // ];
    // reducedScores = behaviorNames.map((name) => {
    //   return filteredScores.find((score) => score.behavior.name === name);
    // });

    // return filteredScores;
    case SCORES_RESETTED:
      return null;
    default:
      return state;
  }
};
