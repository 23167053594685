import {
  DRIVER_STATS_DATE_CHANGED,
  DRIVER_STATS_DATE_RESETTED,
} from "../actions/driverStats";
import { getWeek, calcStartDate, calcEndDate } from "../Utils/functions";

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case DRIVER_STATS_DATE_CHANGED:
      return action.payload;
    case DRIVER_STATS_DATE_RESETTED:
      return {
        weekIndex: getWeek(new Date()) - 1,
        startDate: calcStartDate(new Date()),
        endDate: calcEndDate(new Date()),
      };
    default:
      return state;
  }
};

const initialState = {
  weekIndex: getWeek(new Date()) - 1,
  startDate: calcStartDate(new Date()),
  endDate: calcEndDate(new Date()),
};
