import {
  PORTAL_USERS_FETCHED,
  PORTAL_USER_EDITMODE_TOGGLED,
  PORTAL_USERS_UPDATED,
  PORTAL_USER_CREATED,
  PORTAL_USER_DELETED,
} from "../actions/portalUsers";

let usersWithEditmodeOption = [];
let updatedTableEditmode = [];
let userToBeEdited = {};
let updatedUsersWithFalseEditMode = [];
let newUser = {};
let filteredTable = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case PORTAL_USERS_FETCHED:
      // adding editMode value to each portal user that can be toggled later on
      usersWithEditmodeOption = action.payload.map((user) => {
        return {
          ...user,
          editMode: false,
        };
      });
      return usersWithEditmodeOption;
    case PORTAL_USER_EDITMODE_TOGGLED:
      updatedTableEditmode = state.map((user) => {
        if (user.userId === action.payload) {
          userToBeEdited = {
            ...user,
            editMode: !user.editMode,
          };
          return userToBeEdited;
        }
        return user;
      });
      return updatedTableEditmode;
    case PORTAL_USERS_UPDATED:
      updatedUsersWithFalseEditMode = action.payload.usersData.map((user) => {
        if (user.userId === action.payload.userId) {
          userToBeEdited = {
            ...user,
            editMode: false,
          };
          return userToBeEdited;
        }
        return user;
      });
      return updatedUsersWithFalseEditMode;
    case PORTAL_USER_CREATED:
      newUser = {
        ...action.payload,
        editMode: false,
      };
      return [...state, newUser];
    case PORTAL_USER_DELETED:
      filteredTable = state.filter((user) => user.userId !== action.payload);
      return filteredTable;
    default:
      return state;
  }
};
