import { COMPANY_GROUPS_COACHING_FETCHED } from "../actions/companies";
let groupsData = [];
let group = {};

export default (state = null, action = {}) => {
  switch (action.type) {
    case COMPANY_GROUPS_COACHING_FETCHED:
      group = {
        companyId: action.payload.companyId,
        groups: [...action.payload.groups]
      };
      groupsData.push(group);
      return groupsData;
    default:
      return state;
  }
};