import { DRIVER_CREDITS_DATA_LOADED } from "../actions/driverCreditsTable";

let driverCreditsData = [];


export default (state = null, action = {}) => {
    
    switch (action.type) {
        case DRIVER_CREDITS_DATA_LOADED:
            driverCreditsData = action.payload.map((driver) => {
                return {
                    driverId: driver.driver.driverId,
                    firstName: driver.driver.personalDetails.givenName,
                    lastName: driver.driver.personalDetails.surname,
                    credits:driver.credits,
                }}
            );
            driverCreditsData=sortByName(
                driverCreditsData,
                "firstName"
              );
            return driverCreditsData;
        default:
            return state;
    }
    
}


const sortByName = (arrayToSort, sortProperty) => {
    const result=arrayToSort.sort((a, b) => {
        let nameA = a[sortProperty].toUpperCase(); // regardless of capital letters
        let nameB = b[sortProperty].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
    
        return 0; // names are the same
    });
    
    return result;
}