import {
  DRIVERS_TABLE_FETCHED,
  DRIVER_EDITMODE_TOGGLED,
  DRIVERS_TABLE_UDPATED,
  NEW_DRIVER_ADDED,
  DRIVER_REMOVED,
  DRIVERS_REMOVED,
  SORTING_CHANGED,
  DRIVERS_CSV_DATA_IMPORTED,
  PWD_RESETTED,
} from "../actions/driversTable";
import {
  translateToCode,
  translateToLang,
  phoneNumParserFromCsv,
} from "../../Coaching/Utils/functions";

let driversTableWithEditmodeOption = [];
let driverEmail;
let updatedTableEditmode = [];
let driverToBeEdited = {};
let newDriver = {};
let filteredTable = [];
let sortedByNameTable = [];
let newDataWithFalseEditMode = [];
let formattedImportedData = [];
let dataIncludingImportFromCsv = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case DRIVERS_TABLE_FETCHED:
    
      driversTableWithEditmodeOption = action.payload.map((driver) => {
        driverEmail = driver.personalDetails.identities.find(
          (e) => e.signInType === "emailAddress"|| e.signInType === "userPrincipalName"
        ).issuerAssignedId;
        
        // formatting received data
        return {
          driverId: driver.driverId,
          firstName: driver.personalDetails.givenName,
          lastName: driver.personalDetails.surname,
          phone: driver.personalDetails.mobilePhone
            ? driver.personalDetails.mobilePhone
            : "",
          email: driverEmail ? driverEmail : "",
          companyId: driver.companyDriverId,
          lang: driver.language ? driver.language.code : "unknown",
          editMode: false, // adding editMode value to each driver that can be toggled later on
          group: driver.companyGroup?.name, 
          groupDisplayName:driver.companyGroup?.displayName
        };
      });
      // sorting by first name by default
      sortedByNameTable = sortByName(
        driversTableWithEditmodeOption,
        "firstName"
      );
      return sortedByNameTable;
    case DRIVER_EDITMODE_TOGGLED:
      updatedTableEditmode = state.map((driver) => {
        if (driver.driverId === action.payload) {
          driverToBeEdited = {
            ...driver,
            editMode: !driver.editMode,
          };
          return driverToBeEdited;
        }
        return driver;
      });
      return updatedTableEditmode;
    case DRIVERS_TABLE_UDPATED:
      newDataWithFalseEditMode = state.map((driver) => {
        if (driver.driverId === action.payload.driverId) {
          driverToBeEdited = {
            ...action.payload.data,
            editMode: false,
          };
          return driverToBeEdited;
        }
        return driver;
      });
      return newDataWithFalseEditMode;
    case NEW_DRIVER_ADDED:
      newDriver = {
        ...action.payload,
        editMode: false,
      };
      return [...state, newDriver];
    case DRIVER_REMOVED:
      filteredTable = state.filter(
        (driver) => driver.driverId !== action.payload
      );
      return filteredTable;
    case DRIVERS_REMOVED:
      filteredTable = state.filter(
        (driver) => !action.payload.includes(driver.driverId)        
      );
      return filteredTable;
    case SORTING_CHANGED:
      sortedByNameTable = sortByName(state, action.payload);
      return [...sortedByNameTable];
    case DRIVERS_CSV_DATA_IMPORTED:
      formattedImportedData = action.payload.fileData.map((rowArray, index) => {
        return {
          driverId: action.payload.arrayOfDriverIds[index],
          firstName: rowArray[0],
          lastName: rowArray[1],
          phone: phoneNumParserFromCsv(rowArray[2]),
          email: rowArray[3],
          companyId: rowArray[4],
          lang: rowArray[5],
          group: rowArray[6]?rowArray[6]:"",
        };
      });

      dataIncludingImportFromCsv = [...state, ...formattedImportedData];
      return dataIncludingImportFromCsv;
    case PWD_RESETTED:
      filteredTable = state.filter(
        (driver) => driver.firstName !== action.payload
      );
      return filteredTable;
    default:
      return state;
  }
};

const sortByName = (arrayToBeSorted, sortProperty) => {
  // if (sortProperty === "companyId") {
  //   const result = arrayToBeSorted.sort((a, b) => {
  //     return a[sortProperty] - b[sortProperty];
  //   });
  //   return result;
  // } else {
  let arrayBeingSorted = arrayToBeSorted;
  if (sortProperty === "lang") {
    arrayBeingSorted = arrayToBeSorted.map((driverData) => {
      return {
        ...driverData,
        lang: translateToLang(driverData.lang),
      };
    });
  }
  const result = arrayBeingSorted.sort((a, b) => {
    let nameA = a[sortProperty].toUpperCase(); // regardless of capital letters
    let nameB = b[sortProperty].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0; // names are the same
  });
  if (sortProperty === "lang") {
    const langResult = result.map((driverData) => {
      return {
        ...driverData,
        lang: translateToCode(driverData.lang),
      };
    });
    return langResult;
  }
  return result;
  // }
};
