import {
  MESSAGES_FETCHED,
  MESSAGES_RESETTED,
  MESSAGE_CREATE_SUCCESS,
  TEMPLATED_MESSAGE_CREATE_SUCCESS,
  SET_DOUBLE_TICK_TO_ALL_IN_APP_MESSAGES,
  UPDATE_MESSAGE_WITH_TRANSLATION
} from "../actions/messages";
import moment from "moment";

let formattedMessages = [];
let formattedMessage;
let extendedMessages = [];
let updatedMessagesArray = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case MESSAGES_FETCHED:
      if (action.payload.length > 0) {
        formattedMessages = action.payload
          .map((messageElement) => {
            return {
              messageId: messageElement.messageId,
              //Include seen property as well
              seen: messageElement.seen,
              message: messageElement.messageText,
              date: moment.utc(messageElement.dateTime).local(), // timestamps are being stored as UTC (1 hr earlier) => convert them to local time
              color: "success",
              sender:
                messageElement.direction === 1
                  ? "from"
                  : messageElement.direction === 2
                    ? "to"
                    : "unknown",
              channelType: messageElement.channel.channelType, // 1: Whatsapp OR 2: In-app
              translationId: messageElement.translationId,
              translation: messageElement.translation ? messageElement.translation.translatedText : null
            };
          })
          .reverse(); // reverse the order, so the last item is the latest message

        if (
          state !== null &&
          formattedMessages[0].messageId === state[0].messageId
        ) {
          // if it's the same content (perhaps repeating initial calls on hard reload)
          formattedMessages = [];
          return state;
        }

        if (state !== null) {
          // if there were already messages loaded, add the new ones to them
          return [...formattedMessages, ...state];
        } else {
          // if the state was empty, return only the new ones
          return [...formattedMessages];
        }
      } else {
        // if there are no fetched messages, return the previous state
        formattedMessages = [];
        return state;
      }
    case MESSAGES_RESETTED:
      return null;
    case MESSAGE_CREATE_SUCCESS:
      formattedMessage = {
        messageId: action.payload.messageId,
        message: action.payload.messageText,
        date: moment.utc(action.payload.dateTime).local(), // timestamps are being stored as UTC (1 hr earlier) => convert them to local time
        color: "success",
        sender:
          action.payload.direction === 1
            ? "from"
            : action.payload.direction === 2
              ? "to"
              : "to", // only the coach could have sent it (but would be unknown)
        channelType: action.payload.channel.channelType, // 1: Whatsapp OR 2: In-app
        translationId: action.payload.translationId,
        translation: action.payload.translation ? action.payload.translation.translatedText : null
      };
      extendedMessages = [...state, formattedMessage];
      return extendedMessages;
    case TEMPLATED_MESSAGE_CREATE_SUCCESS:
      formattedMessage = {
        messageId: action.payload.messageId,
        message: action.payload.messageText,
        date: moment.utc(action.payload.dateTime).local(), // timestamps are being stored as UTC (1 hr earlier) => convert them to local time
        color: "success",
        sender:
          action.payload.direction === 1
            ? "from"
            : action.payload.direction === 2
              ? "to"
              : "to", // only the coach could have sent it (but would be unknown)
        channelType: action.payload.channel.channelType, // 1: Whatsapp (templated msg can only be WhatsApp)
      };
      extendedMessages = [...state, formattedMessage];
      return extendedMessages;
    case SET_DOUBLE_TICK_TO_ALL_IN_APP_MESSAGES:
      formattedMessages = state.map(message => {
        if (message.channelType === 2 && message.sender === "to" && message.seen != true) {
          return { ...message, seen: true };
        } else return message;
      })
      return formattedMessages;
    case UPDATE_MESSAGE_WITH_TRANSLATION:
      updatedMessagesArray = state.map((message) => {
        if (message.messageId !== action.payload?.messageId) {
          return message;
        } else {
          return {
            ...message,
            translationId: action.payload?.translationResponse?.translation?.translationId,
            translation: action.payload?.translationResponse?.translation?.translatedText,
          }
        }
      })
      return updatedMessagesArray;
    default:
      return state;
  }
};
