import {
  COACH_NAV_CREATED,
  COACH_NAV_RESETTED,
  COACH_NAV_FILTERED,
  COACH_NAV_UNREAD_ICON_TRUE,
  COACH_NAV_POSTPONED_ICON_TOGGLED,
  COACH_NAV_POSTPONED_STATUS_ADDED,
  COACH_NAV_POSTPONED_STATUS_REMOVED,
  COACH_NAV_UNREAD_STATUS_ADDED,
  COACH_NAV_UNREAD_STATUS_REMOVED,
  COACH_NAV_NEWDRIVER_STATUS_ADDED,
  COACH_NAV_NEWDRIVER_STATUS_REMOVED,
  resetIcons,
  COACH_NAV_ICONS_RESET,
  COACH_NAV_REFRESH_DRIVER_LIST,
  COACH_NAV_LABEL_FILTERED,
  COACH_NAV_OVERDUE_ICON_TOGGLED,
  COACH_NAV_OPENISSUE_STATUS_TOGGLED,
  COACH_NAV_OPENISSUE_STATUS_REMOVED
} from "../actions/drivers";
import { REFRESH_SIDEBAR_WITH_UNREAD_DRIVERS } from "../actions/companies";
import { LANG_CHANGE } from "../LanguageSwitcher";
import { isDriverWithGivenLabel } from "../../Coaching/Utils/functions";
import i18n from "../../../i18n";

let driversArray = [];
let nameA;
let nameB;
let sortedByZScore;
// let sortedZScores;
// let filteredOutlierScores;
// let sortedDrivers;
let driversFromCompany;
let driverToBeUpdated;
const savedState = [];
let coachNaviState;
let match;
let extractedCompanyName;

export default (state = null, action = {}) => {
  switch (action.type) {
    case COACH_NAV_CREATED:
      if (action.payload.driverData.length > 0) {
        driversArray.push(
          action.payload.driverData.map((driver) => {
            if (driver.company && driver.latestWeekScore && driver.status) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                weekscore: driver.latestWeekScore.grade,
                statusObject: driver.status,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company && driver.latestWeekScore) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: driver.latestWeekScore.grade,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company && driver.status) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: -1,
                statusObject: driver.status,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.company) {
              return {
                name: driver.personalDetails.displayName,
                driverId: driver.driverId,
                company: driver.company.companyName,
                group: driver.companyGroup.displayName,
                parentGroup: driver.companyGroup.parentGroupId,
                fullCompanyName: driver.companyGroup.parentGroupId !== null ? driver.company.companyName + ' ' + driver.companyGroup.displayName : driver.company.companyName,
                weekscore: -1,
                outlierScoresArray: driver.outlierScores
                  ? driver.outlierScores.length > 0
                    ? driver.outlierScores
                    : null
                  : null,
              };
            } else if (driver.companyId) {
              // no drivers
              return {
                company: driver.companyId,
                fullCompanyName: driver.companyId,
                group: null,
                parentGroup: null,
              };
            } else {
              // no info
              return state;
            }
          })
        );
        // sort companies alphabetically by company name
        driversArray.sort(function (a, b) {
          nameA = a[0].company.replace(" Overview", "").toUpperCase();
          nameB = b[0].company.replace(" Overview", "").toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // if the names are equal, don't sort
          return 0;
        });

        // sort drivers based on zScore, by each driver’s lowest z-score within the driver’s z-scores. From lowest to highest zScore. With no zScore, to the end.
        // sortedByZScore = driversArray.map((companyArray) => {
        //   // zScores are being sorted inside each driver (ascending)
        //           sortedZScores = companyArray.map((driverObject) => {
        //     if (driverObject.outlierScoresArray) {
        //       // filtering out behaviors
        //       filteredOutlierScores = driverObject.outlierScoresArray
        //         .filter((score) => {
        //           if (score.behavior) {
        //             return (
        //               !score.behavior.name.includes("korte") &&
        //               !score.behavior.name.includes("lange") &&
        //               !score.behavior.name.includes("Snelweg")
        //             );
        //           } else {
        //             return score;
        //           }
        //         })
        //         // removing behaviors that doesn't have a valid zScore value
        //         .filter((score) => {
        //           return score.zScore && typeof score.zScore === "number";
        //         });
        //       filteredOutlierScores.sort((a, b) => a.zScore - b.zScore);
        //       driverObject.outlierScoresArray = filteredOutlierScores;
        //       return driverObject;
        //     } else {
        //       driverObject.outlierScoresArray = [
        //         { zScore: 100, info: "No z-score data." },
        //       ];
        //       return driverObject;
        //     }
        //   });

        //   sortedDrivers = sortedZScores.sort((driverA, driverB) => {
        //     return (
        //       driverA.outlierScoresArray[0].zScore -
        //       driverB.outlierScoresArray[0].zScore
        //     );
        //   });
        //   return sortedDrivers;
        // });

        //sorting based grade/weekscore instead of z-score in ascending order, as drivers with low weekscore should get more attention 
        sortedByZScore = driversArray.map((companyArray) => {
          const driversWithGrade = companyArray.filter((driver) => { return driver.weekscore !== -1 });
          const driversWithoutGrade = companyArray.filter((driver) => { return driver.weekscore === -1 });
          const driversWithGradeSorted = driversWithGrade.sort((a, b) => a.weekscore - b.weekscore)
          return [...driversWithGradeSorted, ...driversWithoutGrade];
        }
        )

        //sort drivers list to show driver with blue dot on top
        sortedByZScore.map((comp) => {
          comp.sort((a, b) => {
            if (a.statusObject && b.statusObject) {
              return b.statusObject.unread - a.statusObject.unread;
            }
          });
        });
      }
      coachNaviState = createSidebarDataset(sortedByZScore, action.payload);
      // console.log("reducer state", coachNaviState);
      return coachNaviState;
    case COACH_NAV_RESETTED:
      coachNaviState = createSidebarDataset(sortedByZScore, action.payload);
      compareStatesToPersistChanges(
        coachNaviState.items[1].children,
        action.payload.currentState.items[1].children
      );
      return coachNaviState;
    case COACH_NAV_FILTERED:
      savedState.push(state);
      if (action.payload) {
        let searchResult = state.items[1].children
          .map((company) => {
            let filteredDrivers = company.children.filter((driver) => {
              return driver.name
                .toLowerCase()
                .includes(action.payload.toLowerCase()) || driver.attributes.id === action.payload;
            });
            if (filteredDrivers.length > 0) {
              company.children = filteredDrivers;
            } else {
              company.children = [];
            }
            return company;
          })
          .filter((company) => {
            return company.children.length > 0;
          });

        state.items[1].children = searchResult;
        return { ...state };
      } else {
        return { ...state };
      }
    case COACH_NAV_LABEL_FILTERED:
      savedState.push(state);
      if (action.payload) {
        let searchResult = state.items[1].children
          .map((company) => {
            let filteredDrivers = company.children.filter((driver) => {
              return isDriverWithGivenLabel(driver, action.payload.labels);
            });
            if (filteredDrivers.length > 0) {
              company.children = filteredDrivers;
            } else {
              company.children = [];
            }
            return company;
          })
          .filter((company) => {
            return company.children.length > 0;
          });
        state.items[1].children = searchResult;
        return { ...state };
      } else {
        return { ...state };
      }

    case LANG_CHANGE:
      if (state !== null) {
        if (action.payload === "en") {
          state.items[1].name = "Companies";
          return { ...state };
        } else {
          state.items[1].name = "Bedrijven";
          return { ...state };
        }
      } else {
        return null;
      }
    case COACH_NAV_UNREAD_ICON_TRUE:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            unRead: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_ICON_TOGGLED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            postponed: !driverToBeUpdated.info.postponed,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_STATUS_ADDED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            postponed: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_STATUS_REMOVED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            postponed: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_UNREAD_STATUS_ADDED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            unRead: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_UNREAD_STATUS_REMOVED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            unRead: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_NEWDRIVER_STATUS_ADDED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            newDriver: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_NEWDRIVER_STATUS_REMOVED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            newDriver: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_OPENISSUE_STATUS_TOGGLED:
      if(action.payload.companyId.indexOf(":") >= 0){
            extractedCompanyName=action.payload.companyId.replace(":"," ");
      }else{
        extractedCompanyName=action.payload.companyId;
      }
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === extractedCompanyName;
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            openIssue: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
      case COACH_NAV_OPENISSUE_STATUS_REMOVED:
      if(action.payload.companyId.indexOf(":") >= 0){
            extractedCompanyName=action.payload.companyId.replace(":"," ");
      }else{
        extractedCompanyName=action.payload.companyId;
      }
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === extractedCompanyName;
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            openIssue: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_ICONS_RESET:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            unRead: false,
            unOpened: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_OVERDUE_ICON_TOGGLED:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyId.replace(":"," ");
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            overDue: false,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_REFRESH_DRIVER_LIST:
      //Re-order the driver list in sidebar if a driver is marked as Unread
      match = state.items[1].children.filter((comp) => {
        if (comp.name.toLowerCase() === action.payload.companyId.toLowerCase())
          return comp;
      });
      if (match[0] && match[0].children) {
        match[0].children.sort((a, b) => {
          return b.info.unRead - a.info.unRead;
        });
      }

      return { ...state };

    //DUPLICATE case as UNREAD_ICON_TRUE but had to include here to specifically call reducer of coachNavi, not driver reducer
    case REFRESH_SIDEBAR_WITH_UNREAD_DRIVERS:
      driversFromCompany = state.items[1].children.find((company) => {
        return company.name.toLowerCase() === action.payload.companyName;
      });

      if (driversFromCompany) {
        driverToBeUpdated = driversFromCompany.children.find((driver) => {
          return driver.attributes.id === action.payload.drivers;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.info = {
            ...driverToBeUpdated.info,
            unRead: true,
          };
          driverToBeUpdated.icon = dotCheck(driverToBeUpdated.info);
          return { ...state };
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    default:
      return state;
  }
};

// generating random booleans for displaying icon variations in sidebar
// const randomIconGenerator = () => {
//   let randomBooleans = [];
//   for (let j = 0; j < 4; j++) {
//     const randomBoolean = Math.round(Math.random() * 1);
//     randomBooleans.push(randomBoolean);
//   }
//   return {
//     postponed: randomBooleans[0],
//     unOpened: randomBooleans[1],
//     feedback: randomBooleans[2],
//     unRead: randomBooleans[3],
//   };
// };

// checks which dots are needed for each driver
export const dotCheck = (statusObject) => {
  let numberOfStatuses = 0;
  Object.entries(statusObject).map(([key, value]) => {
    //login/loggedInDriver status is differently treated than other statuses: We want to filter out drivers who have loggedInDriver status as FALSE whwreas we filter drivers who have other status as TRUE
    if (key !== "unOpened" && value) {
      numberOfStatuses++;
    }
  });
  if (numberOfStatuses > 1) {
    return `fa fa-exclamation-circle sidebar-nav-icon--red`;
  } else {
    if (
      statusObject.unOpened &&
      !statusObject.unRead &&
      !statusObject.overDue &&
      !statusObject.postponed &&
      !statusObject.newDriver &&
      !statusObject.login&&
      !statusObject.openIssue
    ) {
      return "fa fa-question-circle-o sidebar-nav-icon--gray";
    }
    if (statusObject.postponed) {
      return "fa fa-pause-circle-o sidebar-nav-icon--orange";
    }
    if (statusObject.unRead) {
      return "fa fa-commenting-o fa-solid sidebar-nav-icon--blue";
    }
    if (statusObject.overDue) {
      return "fa fa-clock-o fa-solid sidebar-nav-icon--red";
    }
    if (statusObject.newDriver) {
      return "fa fa-graduation-cap sidebar-nav-icon--green";
    }
    if (statusObject.login) {
      return "fa fa-mobile sidebar-nav-icon--black";
    }
    if(statusObject.openIssue){
      return "fa fa-times-circle fa-solid sidebar-nav-icon--red";
    }
  }
};

//Create dataset for sidebar
export const createSidebarDataset = (drivers, action) => {
  return {
    items: [
      {
        title: true,
        name: "Coaching",
      },
      {
        name: i18n.t("translation:Company.5"),
        url: "", // if the url is empty string, the dropdown loads as open by default. if it has a path or "#", it will load as closed
        icon: "icon-speech",
        attributes: {
          id: "first_nav_toggle",
        },
        children: drivers.map((company) => {
          // console.log("NAVIGATION STATE", coachNaviState)
          let companyNameNew;
          let companyUrlNew;
          let currentData = coachNaviState?.items[1].children;
          let currenDataHaveCompany = currentData?.find((companyName) => companyName?.name?.includes(company[0].fullCompanyName));
          if (currenDataHaveCompany) {
            companyNameNew = currenDataHaveCompany?.name;
            companyUrlNew = currenDataHaveCompany?.url;
          }
          else {
            companyNameNew = action.group !== null && company[0].parentGroup !== null ? company[0].company + " " + company[0].group : company[0].company;
            companyUrlNew = action.group !== null && company[0].parentGroup !== null ? `/coaching/${company[0].company}:${company[0].group}` : `/coaching/${company[0].company}`

          }

          //old implementation for groups
          // const companyName = action.group !== null && company[0].parentGroup!== null ? company[0].company + " " + company[0].group : company[0].company;
          // const companyUrl = action.group !== null && company[0].parentGroup !== null ? `/coaching/${company[0].company}:${company[0].group}` : `/coaching/${company[0].company}`

          return {
            name: companyNameNew,
            url: companyUrlNew,
            icon: "icon-briefcase",
            attributes: {
              onClick: () => {
                return;
              },
              id: companyNameNew,
            },
            badge: {
              variant: "info",
              text: ``,
            },
            children: company[0].name
              ? company.map((driver) => {
                //driver.group && console.log(`/coaching/${company[0].company.toString().toLowerCase()}:${driver.group.toString().toLowerCase()}`);
                const driverUrl = driver.parentGroup !== null ? `/coaching/${company[0].company.toLowerCase()}:${driver.group.toLowerCase()}/${driver.driverId
                  }` : `/coaching/${company[0].company.toString().toLowerCase()}/${driver.driverId
                  }`
                return {
                  name: "  " + driver.name,
                  url: driverUrl, /*`/coaching/${company[0].company.toString().toLowerCase()}/${driver.driverId
                    }`,*/
                  //If a mentor/ext coach is logged in(coach-to-coach portal), do not show blue/orange/grey icons
                  icon: driver.statusObject
                    ? action.isMentor
                      ? action.isC2cLoginInfoRequested
                        ? dotCheck({
                          // passing only login status of the driver if mentor wants to see the login.
                          postponed: false,
                          unOpened: false,
                          feedback: false,
                          unRead: false,
                          overDue: false,
                          newDriver: false,
                          openIssue:false,
                          login: driver.statusObject.loggedInDriver,
                        })
                        : ""
                      : dotCheck({
                        // predefined values unti it cannot connect to the backend -> backend will send these values
                        postponed: driver.statusObject.postponed,
                        unOpened: driver.statusObject.unopened,
                        feedback: false,
                        unRead: driver.statusObject.unread,
                        overDue: driver.statusObject.overdue,
                        newDriver: driver.statusObject.newDriver,
                        login: driver.statusObject.loggedInDriver,
                        openIssue:driver.statusObject.openIssue,
                      })
                    : "",
                  info: driver.statusObject
                    ? {
                      postponed: driver.statusObject.postponed,
                      unOpened: driver.statusObject.unopened,
                      feedback: false,
                      unRead: driver.statusObject.unread,
                      overDue: driver.statusObject.overdue,
                      newDriver: driver.statusObject.newDriver,
                      login: driver.statusObject.loggedInDriver,
                      openIssue:driver.statusObject.openIssue,
                    }
                    : {
                      postponed: false,
                      unOpened: false,
                      feedback: false,
                      unRead: false,
                      overDue: false,
                      newDriver: false,
                      login: false,
                      openIssue:false,
                    },
                  // icon: "icon-briefcase",
                  attributes: {
                    id: driver.driverId,
                    onClick: () => {
                      // if the child is clicked, the company dropdown won't close
                      setTimeout(() => {
                        document.getElementById(
                          companyNameNew
                        ).parentElement.className =
                          "nav-item nav-dropdown open";
                      }, 300);

                      // if the child is clicked, its unOpened and unRead icons should be set to false
                      action.dispatch(
                        resetIcons(
                          companyNameNew.toString().toLowerCase(),
                          driver.driverId
                        )
                      );
                    },
                  },
                  badge: {
                    variant: "transparent",
                    // class: iconCheck(child),
                    class: "nav-badge",
                    text:
                      driver.weekscore === -1
                        ? " "
                        : (
                          Math.round((driver.weekscore / 10) * 10) / 10
                        ).toFixed(1),
                  },
                };
              })
              : [],
          };
        }),
      },
    ],
  };
};

export const compareStatesToPersistChanges = (nextState, prevState) => {
  prevState.map((compPrev) => {
    nextState.map((compNext) => {
      if (compPrev.name === compNext.name) {
        compPrev.children.map((driverPrev) => {
          compNext.children.map((driverNext) => {
            if (driverPrev.name === driverNext.name) {
              driverNext.info = {
                ...driverPrev.info,
              };
              driverNext.icon = dotCheck({
                postponed: driverNext.info.postponed,
                unOpened: driverNext.info.unOpened,
                feedback: driverNext.info.feedback,
                unRead: driverNext.info.unRead,
                overDue: driverNext.info.overDue,
                newDriver: driverNext.info.newDriver,
                login: driverNext.info.login,
                openIssue:driverNext.info.openIssue
              });
            }
          });
        });
        //When sidebar is reset, drivers with unread status do not slide up hence sort those company drivers again
        compNext.children.sort((a, b) => {
          return b.info.unRead - a.info.unRead;
        });
      }
    });
  });
};
