import { DRIVER_VS_SCORES_DATA_FETCHED, RESET_DRIVER_VS_SCORES, APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE, APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE, RESTORE_DRIVER_VS_SCORES_STATE, APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE, CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE } from "../actions/scoreOverview";

let driverVsScoresState = {
  loading: false,
  data: [],
  date: {},
  fullDriverList: []
}
let filteredDrivers = []
let savedStateForSearch = [];

export default (state = driverVsScoresState, action = {}) => {
  switch (action.type) {
    case DRIVER_VS_SCORES_DATA_FETCHED:
      return {
        ...state,
        date: action.payload.date,
        data: action.payload.data,
        fullDriverList: action.payload.data
      };
    case RESET_DRIVER_VS_SCORES:
      return {
        ...driverVsScoresState
      };
    case RESTORE_DRIVER_VS_SCORES_STATE:
      return {
        ...state,
        data: [...state.fullDriverList]
      }
    case APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE:
      savedStateForSearch = [...state.data];
      filteredDrivers = state?.data?.filter((driver) => driver.driversFirstName.toLowerCase().includes(action.payload.toLowerCase()) || driver.driversSurName.toLowerCase().includes(action.payload.toLowerCase()))
      if (!filteredDrivers) {
        return state;
      }
      return {
        ...state,
        data: [...filteredDrivers]
      }
    case CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE:
      return {
        ...state,
        data: [...savedStateForSearch]
      }
    case APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE:
      filteredDrivers = state.fullDriverList?.filter(driver => evaluateFilters(driver, action.payload))
      // console.log("previous state and filtered state and lengths", state?.fullDriverList, filteredDrivers,state?.fullDriverList?.length, filteredDrivers?.length)
      return {
        ...state,
        data: filteredDrivers
      }
    case APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE:
      if (action.payload.filters[0]?.scoreType === '' || action.payload.filters[0]?.value === '' || action.payload.filters[0]?.operator === '') {
        // console.log("no filters, returning new full data")
        return {
          ...state,
          date: action.payload.date,
          data: action.payload.data,
          fullDriverList: action.payload.data
        }
      }
      filteredDrivers = action?.payload?.data?.filter(driver => {
        return evaluateFilters(driver, action.payload?.filters)
      })
      // console.log("new data state and filtered state and lengths in new action dispatch", action?.payload?.data, filteredDrivers,action?.payload?.data?.length, filteredDrivers?.length)
      return {
        ...state,
        date: action.payload.date,
        data: filteredDrivers,
        fullDriverList: action.payload.data
      }
    default:
      return state;
  }
};

const evaluateFilters = (driver, filters) => {
  const logic = filters[0]?.logic || 'OR'; // Default to OR if no logic is specified

  const compare = (behavior, filter) => {
    // console.log("behavior and filter in function",behavior,filter)
    const value = behavior.value / 10; // Adjusting the value as per your logic
    switch (filter.operator) {
      case '<':
        return value < filter.value;
      case '>':
        return value > filter.value;
      case '<=':
        return value <= filter.value;
      case '>=':
        return value >= filter.value;
      default:
        return false;
    }
  };


  if (logic === 'OR') {
    return filters.some(filter => {
      const behavior = driver.driversVersusScoresBehaviors.find(b => b.behaviorId === filter.scoreType);
      if (!behavior) return false;

      // const value = (behavior.value/10);
      // // console.log(typeof(value),typeof(filter.value),value, filter.value)
      // switch (filter.operator) {
      //   case '<':
      //     return value < filter.value;
      //   case '>':
      //     return value > filter.value;
      //   case '<=':
      //     return value <= filter.value;
      //   case '>=':
      //     return value >= filter.value;
      //   default:
      //     return false;
      // }
      return compare(behavior, filter);
    });
  } else if (logic === 'AND') {
    return filters.every(filter => {
      const behavior = driver.driversVersusScoresBehaviors.find(b => b.behaviorId === filter.scoreType);
      if (!behavior) return false;

      // const value = behavior.value;
      // switch (filter.operator) {
      //   case '<':
      //     return value < filter.value;
      //   case '>':
      //     return value > filter.value;
      //   case '<=':
      //     return value <= filter.value;
      //   case '>=':
      //     return value >= filter.value;
      //   default:
      //     return false;
      // }
      return compare(behavior, filter);
    });
  }

  return false;
};