import {
  DRIVERS_FETCHED,
  COACH_NAV_UNREAD_ICON_TRUE,
  COACH_NAV_POSTPONED_ICON_TOGGLED,
  COACH_NAV_POSTPONED_STATUS_ADDED,
  COACH_NAV_POSTPONED_STATUS_REMOVED,
  COACH_NAV_UNREAD_STATUS_ADDED,
  COACH_NAV_UNREAD_STATUS_REMOVED,
  COACH_NAV_NEWDRIVER_STATUS_ADDED,
  COACH_NAV_NEWDRIVER_STATUS_REMOVED,
  COACH_NAV_ICONS_RESET,
  REFRESH_SIDEBAR,
  SET_TYPED_MESSAGE,
  CLEAR_TYPED_MESSAGE
} from "../actions/drivers";

let currentCompanyDrivers;
let driverToBeUpdated;

export default (state = [], action = {}) => {
  switch (action.type) {
    case DRIVERS_FETCHED:
      state.push(action.payload);
      return state;
    case COACH_NAV_UNREAD_ICON_TRUE:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            unread: true,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_ICON_TOGGLED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            postponed: !driverToBeUpdated.status.postponed,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_STATUS_ADDED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            postponed: true,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_POSTPONED_STATUS_REMOVED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            postponed: false,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_UNREAD_STATUS_ADDED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            unread: true,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_UNREAD_STATUS_REMOVED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            unread: false,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case COACH_NAV_NEWDRIVER_STATUS_ADDED:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            newDriver: true,
          };
          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
      case COACH_NAV_NEWDRIVER_STATUS_REMOVED:
        currentCompanyDrivers =
          state && state.length > 0
            ? state.find((driversArrayOfCompany) => {
                return (
                  driversArrayOfCompany.length > 0 &&
                  driversArrayOfCompany.find(
                    (driver) => driver.driverId === action.payload.driverId
                  )
                );
              })
            : null;
  
        if (currentCompanyDrivers) {
          driverToBeUpdated = currentCompanyDrivers.find((driver) => {
            return driver.driverId === action.payload.driverId;
          });
          if (driverToBeUpdated) {
            driverToBeUpdated.status = {
              ...driverToBeUpdated.status,
              newDriver: false,
            };
            return [...state];
          } else {
            // couldn't find such driver
            return state;
          }
        } else {
          // couldn't find such company
          return state;
        }
    case COACH_NAV_ICONS_RESET:
      currentCompanyDrivers =
        state && state.length > 0
          ? state.find((driversArrayOfCompany) => {
              return (
                driversArrayOfCompany.length > 0 &&
                driversArrayOfCompany.find(
                  (driver) => driver.driverId === action.payload.driverId
                )
              );
            })
          : null;

      if (currentCompanyDrivers) {
        driverToBeUpdated = currentCompanyDrivers.find((driver) => {
          return driver.driverId === action.payload.driverId;
        });
        if (driverToBeUpdated) {
          driverToBeUpdated.status = {
            ...driverToBeUpdated.status,
            unread: false,
            unopened: false,
          };

          return [...state];
        } else {
          // couldn't find such driver
          return state;
        }
      } else {
        // couldn't find such company
        return state;
      }
    case REFRESH_SIDEBAR:
      //Here drivers list in store is being re-ordered
      state.map((comp) => {
        comp.sort((a, b) => {
          if (a.status && b.status) {
            return b.status.unread - a.status.unread;
          }
        });
      });

      return state;
    case SET_TYPED_MESSAGE:
      state.typedMessage = action.payload;
      return state;
    case CLEAR_TYPED_MESSAGE:
      state.typedMessage = "";
      return state;
    default:
      return state;
  }
};
