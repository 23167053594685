import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
let accounts;

export const GROUP_SETTINGS_FETCHED="GROUP_SETTINGS_FETCHED"
const groupSettingsFetched = (groupSettings) => ({
    type: GROUP_SETTINGS_FETCHED,
    payload:groupSettings,
})

export const fetchGroupSettings = (group) => async (dispatch, getState) => {
    const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
    const { name, groupId } = group;
  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
    
  request
    .get(`${baseURL}/portal/management/CompanyGroupSettings/GetStringMinGoalSettingOfGroup/${groupId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from companyGroups API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
        // console.log("response from group settings api",response.text, name);
        dispatch(groupSettingsFetched({name,range:response.text}))
  })
}