import {
  COMPANY_GROUPS_FETCHED,
  COMPANY_GROUP_EDITMODE_TOGGLED,
  COMPANY_GROUP_NAME_UPDATED,
  COMPANY_GROUP_CREATED,
  COMPANY_GROUP_DELETED,
} from "../actions/companyGroups";

let groupsWithEditmodeOption = [];
let updatedTableEditmode = [];
let groupToBeEdited = {};
let updatedGroupsWithFalseEditMode = [];
let newGroup = {};
let filteredTable = [];

export default (state = null, action = {}) => {
  switch (action.type) {
    case COMPANY_GROUPS_FETCHED:
      // adding editMode value to each company group that can be toggled later on
      groupsWithEditmodeOption = action.payload.map((group) => {
        return {
          ...group,
          editMode: false,
        };
      });
      return groupsWithEditmodeOption;
    case COMPANY_GROUP_EDITMODE_TOGGLED:
      updatedTableEditmode = state.map((group) => {
        if (group.groupId === action.payload) {
          groupToBeEdited = {
            ...group,
            editMode: !group.editMode,
          };
          return groupToBeEdited;
        }
        return group;
      });
      return updatedTableEditmode;
    case COMPANY_GROUP_NAME_UPDATED:
      updatedGroupsWithFalseEditMode = action.payload.groupsData.map(
        (group) => {
          if (group.groupId === action.payload.groupId) {
            groupToBeEdited = {
              ...group,
              editMode: false,
            };
            return groupToBeEdited;
          }
          return group;
        }
      );
      return updatedGroupsWithFalseEditMode;
    case COMPANY_GROUP_CREATED:
      newGroup = {
        ...action.payload,
        editMode: false,
      };
      return [...state, newGroup];
    case COMPANY_GROUP_DELETED:
      filteredTable = state.filter((group) => group.groupId !== action.payload);
      return filteredTable;
    default:
      return state;
  }
};
