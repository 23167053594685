import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
import {
  postponedStatusAddedToDriver,
  postponedStatusRemovedFromDriver,
  unreadStatusAddedToDriver,
  unreadStatusRemovedFromDriver,
  newDriverStatusAddedToDriver,
  newDriverStatusRemovedFromDriver,
} from "../../Coaching/actions/driver";

let accounts;

// fetching all the drivers
export const DRIVERS_FETCHED = "DRIVERS_FETCHED";

export const COACH_NAV_REFRESH_DRIVER_LIST = "COACH_NAV_REFRESH_DRIVER_LIST";

export const STORE_LOCATION_SPECIFIC_DATA = "STORE_LOCATION_SPECIFIC_DATA";
export const SET_TYPED_MESSAGE = 'SET_TYPED_MESSAGE';
export const CLEAR_TYPED_MESSAGE = 'CLEAR_TYPED_MESSAGE';

export const refreshDriverList = (companyId) => ({
  type: COACH_NAV_REFRESH_DRIVER_LIST,
  payload: { companyId },
});

//Refresh the sidebar-> Can be removed as it updates drivers state
export const REFRESH_SIDEBAR = "REFRESH_SIDEBAR";

export const refreshSidebar = () => ({
  type: REFRESH_SIDEBAR,
});

export const setTypedMessage = (message,id) => ({
  type: SET_TYPED_MESSAGE,
  payload: {message,id},
});

export const clearTypedMessage = () => ({
  type: CLEAR_TYPED_MESSAGE,
});


const driversFetched = (drivers) => ({
  type: DRIVERS_FETCHED,
  payload: drivers,
});

export const loadDrivers =
  (companyId, companyName, groupsData) => async (dispatch, getState) => {
    const store = getState();
    //Accessing mentor data if mentor/external coach logged in along with auth
    var { auth, loggedInMentorData, roles } = store;
    let token = auth?.accessToken;
    let isMentorLoggedIn = loggedInMentorData !== null;
    let isC2cLoginInfoRequested = false;

    //check for showing login status in coach2coach portal. Roles returns "c2clogininfo" for the c2c accounts who wants to see the login status of drivers
    if (isMentorLoggedIn) {
      isC2cLoginInfoRequested = roles?.find((role) => role.roleString === "c2clogininfo") ? true : false;
    }

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(
        `${baseURL}/portal/coaching/Drivers/GetDrivers?companyId=${companyId}&includePersonalDetails=true&skipEmpty=true&includeWeekScore=true&includeStatuses=true&includeCompany=true&includeOutlierScores=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res && res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(driversFetched(response.body));
        if (response.body.length === 0) {
          dispatch(coachNavCreated([{ companyId: companyName }], dispatch, false, null, false));
        } else
          if (groupsData.length > 1) {
            groupsData.map(group => {
              let filteredDrivers = response.body.filter(driver =>
                driver.companyGroup.name ? driver.companyGroup.name.toLowerCase() === group.name.toLowerCase() : null
              )

              // if (filteredDrivers.length > 0) {
              //   dispatch(
              //     coachNavCreated(filteredDrivers, dispatch, isMentorLoggedIn, group.name, isC2cLoginInfoRequested)
              //   );
              // }
              //commenting out the parent group Navlink till dshbaord rollout
              if (group.parentGroupId === null) {
                dispatch(coachNavCreated([{ companyId: `${companyName} Overview` }], dispatch, false, null, false));
              }
              else {
                if (filteredDrivers.length > 0) {
                  dispatch(
                    coachNavCreated(filteredDrivers, dispatch, isMentorLoggedIn, group.name, isC2cLoginInfoRequested)
                  );
                }
              }

            })
          } else {
            dispatch(
              coachNavCreated(response.body, dispatch, isMentorLoggedIn, null, isC2cLoginInfoRequested)
            );
          }
      }
      )
      .catch(console.error);
  };

// passing the coaching navigation to the sidebar in DefaultLayout
export let coachNavigation;

// creating coaching navigation
export const COACH_NAV_CREATED = "COACH_NAV_CREATED";

const coachNavCreated = (driverData, dispatch, isMentor, group, isC2cLoginInfoRequested) => ({
  type: COACH_NAV_CREATED,
  payload: {
    driverData: driverData,
    dispatch: dispatch,
    isMentor: isMentor,
    group: group,
    isC2cLoginInfoRequested: isC2cLoginInfoRequested
  },
});

// filtering the coaching navigation for driver search functionality
export const COACH_NAV_FILTERED = "COACH_NAV_FILTERED";

export const COACH_NAV_LABEL_FILTERED = "COACH_NAV_LABEL_FILTERED";

export const FILTERED_OUTPUT = "FILTERED_OUTPUT";

export const filterNavOptions = (keyword) => ({
  type: COACH_NAV_FILTERED,
  payload: keyword,
});

export const filterDriversUsingLabels = (labels, dispatch) => ({
  type: COACH_NAV_LABEL_FILTERED,
  payload: {
    labels: labels,
    dispatch: dispatch,
  },
});

export const filteredOutput = (value) => ({
  type: FILTERED_OUTPUT,
  payload: value,
});

export const undoSearch = () => (dispatch, getState) => {
  const store = getState();
  let { undoableCoachNavi } = store;
  dispatch(coachNavUndo(dispatch, undoableCoachNavi.present));
};

// resetting the coaching navigation without refetching
export const COACH_NAV_RESETTED = "COACH_NAV_RESETTED";

export const coachNavUndo = (dispatch, currentState) => ({
  type: COACH_NAV_RESETTED,
  payload: {
    dispatch: dispatch,
    currentState,
  },
});

// displaying blue icon by setting the unRead icon to true for the driver
export const COACH_NAV_UNREAD_ICON_TRUE = "COACH_NAV_UNREAD_ICON_TRUE";

export const iconSetToUnread = (companyId, driverId) => ({
  type: COACH_NAV_UNREAD_ICON_TRUE,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const setIconToUnread =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusunread?driverId=${driverId}&value=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(iconSetToUnread(companyId, driverId));
        //Order driver list again after putting one driver blue dot
        dispatch(refreshSidebar());
        dispatch(refreshDriverList(companyId));
      })
      .catch(console.error);
  };

// displaying orange icon by setting the postponed icon to true for the driver
export const COACH_NAV_POSTPONED_ICON_TOGGLED =
  "COACH_NAV_POSTPONED_ICON_TOGGLED";

export const postponedIconToggled = (companyId, driverId) => ({
  type: COACH_NAV_POSTPONED_ICON_TOGGLED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const togglePostponedIcon =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(`${baseURL}/portal/coaching/status/getstatus?driverId=${driverId}`)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(setToggleValuePostponed(response.body, companyId, driverId));
      })
      .catch(console.error);
  };

export const setToggleValuePostponed =
  (statusObject, companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    const oppositeValue = !statusObject.postponed;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatuspostponed?driverId=${driverId}&value=${oppositeValue}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(postponedIconToggled(companyId, driverId));
      })
      .catch(console.error);
  };

// Add postponed status to driver from dropdown
export const COACH_NAV_POSTPONED_STATUS_ADDED =
  "COACH_NAV_POSTPONED_STATUS_ADDED";

export const postponedStatusAdded = (companyId, driverId) => ({
  type: COACH_NAV_POSTPONED_STATUS_ADDED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const setDriverToPostponed =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatuspostponed?driverId=${driverId}&value=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(postponedStatusAdded(companyId, driverId));
        dispatch(postponedStatusAddedToDriver());
      })
      .catch(console.error);
  };

// Remove postponed status from driver via Remove button
export const COACH_NAV_POSTPONED_STATUS_REMOVED =
  "COACH_NAV_POSTPONED_STATUS_REMOVED";

export const postponedStatusRemoved = (companyId, driverId) => ({
  type: COACH_NAV_POSTPONED_STATUS_REMOVED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const unsetDriverFromPostponed =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatuspostponed?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(postponedStatusRemovedFromDriver());
        dispatch(postponedStatusRemoved(companyId, driverId));
      })
      .catch(console.error);
  };

// Add unread status to driver from dropdown
export const COACH_NAV_UNREAD_STATUS_ADDED = "COACH_NAV_UNREAD_STATUS_ADDED";

export const unreadStatusAdded = (companyId, driverId) => ({
  type: COACH_NAV_UNREAD_STATUS_ADDED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const setDriverToUnread =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusunread?driverId=${driverId}&value=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(unreadStatusAdded(companyId, driverId));
        dispatch(unreadStatusAddedToDriver());
        //Order driver list again after putting one driver blue dot
        dispatch(refreshSidebar());
        dispatch(refreshDriverList(companyId));
      })
      .catch(console.error);
  };

// Remove postponed status from driver via Remove button
export const COACH_NAV_UNREAD_STATUS_REMOVED =
  "COACH_NAV_UNREAD_STATUS_REMOVED";

export const unreadStatusRemoved = (companyId, driverId) => ({
  type: COACH_NAV_UNREAD_STATUS_REMOVED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const unsetDriverFromUnread =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusunread?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(unreadStatusRemovedFromDriver());
        dispatch(unreadStatusRemoved(companyId, driverId));
      })
      .catch(console.error);
  };

// Add newDriver status to driver from dropdown
export const COACH_NAV_NEWDRIVER_STATUS_ADDED =
  "COACH_NAV_NEWDRIVER_STATUS_ADDED";

export const newDriverStatusAdded = (companyId, driverId) => ({
  type: COACH_NAV_NEWDRIVER_STATUS_ADDED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const setDriverAsNewDriver =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusnewdriver?driverId=${driverId}&value=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(newDriverStatusAdded(companyId, driverId));
        dispatch(newDriverStatusAddedToDriver());
      })
      .catch(console.error);
  };

// Remove postponed status from driver via Remove button
export const COACH_NAV_NEWDRIVER_STATUS_REMOVED =
  "COACH_NAV_NEWDRIVER_STATUS_REMOVED";

export const newDriverStatusRemoved = (companyId, driverId) => ({
  type: COACH_NAV_NEWDRIVER_STATUS_REMOVED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const unsetDriverAsNewDriver =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusnewdriver?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(newDriverStatusRemovedFromDriver());
        dispatch(newDriverStatusRemoved(companyId, driverId));
      })
      .catch(console.error);
  };

//toggle openIssue status when a new issue is reported
  export const COACH_NAV_OPENISSUE_STATUS_TOGGLED="COACH_NAV_OPENISSUE_STATUS_TOGGLED"

  export const openIssueStatusAdded = (companyId, driverId) => ({
    type: COACH_NAV_OPENISSUE_STATUS_TOGGLED,
    payload: {
      companyId: companyId,
      driverId: driverId,
    },
  });

  //Remove openIssue status when a new issue is reported
  export const COACH_NAV_OPENISSUE_STATUS_REMOVED="COACH_NAV_OPENISSUE_STATUS_REMOVED"

  export const openIssueStatusRemoved = (companyId, driverId) => ({
    type: COACH_NAV_OPENISSUE_STATUS_REMOVED,
    payload: {
      companyId: companyId,
      driverId: driverId,
    },
  });

// deleting blue and orange icons if the driver name gets clicked in the sidebar (meaning, it's not unRead and not unOpened)
export const COACH_NAV_ICONS_RESET = "COACH_NAV_ICONS_RESET";

export const iconsResetted = (companyId, driverId) => ({
  type: COACH_NAV_ICONS_RESET,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const resetIcons =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(`${baseURL}/portal/coaching/status/getstatus?driverId=${driverId}`)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        // before
        if (response.body.unread || response.body.unopened) {
          // status icons needs to be resetted, because the driver page got opened
          // after: reset every time, as there were issues, resulting in persisting dots
          dispatch(resetIconsIfNeeded(companyId, driverId));
        } else {
          return;
        }
      })
      .catch(console.error);
  };

export const resetIconsIfNeeded =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusunopened?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .then(() => {
        //Do not remove Unread status when coach opens driver page, let coach remove it manually
        //dispatch(resetUnread(companyId, driverId));
        //dispatch(unsetDriverFromUnread(companyId, driverId));
      })
      .catch(console.error);
  };

export const resetUnread =
  (companyId, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusunread?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(iconsResetted(companyId, driverId));
        dispatch(refreshDriverList(companyId));
      })
      .catch(console.error);
  };

//Toggle overdue status for driver when coach sends a message to driver
export const COACH_NAV_OVERDUE_ICON_TOGGLED = "COACH_NAV_OVERDUE_ICON_TOGGLED";

const toggleOverdueStatus = (driverId, companyId) => ({
  type: COACH_NAV_OVERDUE_ICON_TOGGLED,
  payload: {
    companyId: companyId,
    driverId: driverId,
  },
});

export const setOverdueToContacted =
  (driverId, companyId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .post(
        `${baseURL}/portal/coaching/status/poststatusoverdue?driverId=${driverId}&value=false`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from drivers API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(() => {
        dispatch(toggleOverdueStatus(driverId, companyId));
      })
      .catch(console.error);
  };

// undo searching in the coaching navigation without refetching
export const UNDO = "UNDO";
