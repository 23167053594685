import { COMPANY_BEHAVIORS_FETCHED, CLEAR_COMPANY_BEHAVIORS } from "../actions/scoreOverviewInCoaching";

const initialState={
    companyId:"",
    behaviors:[]
}
export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_BEHAVIORS_FETCHED:
            return {
                companyId:action.payload.companyId,
                behaviors:action.payload.data
            };
         case CLEAR_COMPANY_BEHAVIORS:
                return {
                    ...initialState
                };
        default:
            return state;
    }
}