import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { loadDrivers } from "./drivers";
import { batch } from "react-redux";
import { getAccessToken } from "../../../services/commonFunctions";
import { refreshDriverList } from "./drivers";
import { loadCompanyFeatures } from "./features";

//Fetching all the companies
export const COMPANIES_FETCHED = "COMPANIES_FETCHED";

//Fetching all groups of company
export const COMPANY_GROUPS_COACHING_FETCHED = "COMPANY_GROUPS_COACHING_FETCHED";

//Get new unread drivers every 2 minutes
export const REFRESH_SIDEBAR_WITH_UNREAD_DRIVERS =
  "REFRESH_SIDEBAR_WITH_UNREAD_DRIVERS";

//Recover message typed by coach
// export const RECOVER_COACH_MSG_WHILE_SIDEBAR_REFRESH =
//   "RECOVER_COACH_MSG_WHILE_SIDEBAR_REFRESH";

export const RESET_COACH_MESSAGE_ON_DRIVER_CHANGE="RESET_COACH_MESSAGE_ON_DRIVER_CHANGE"

//Update sidebar with new unread drivers
const refreshSidebarWithUnreadDriver = (companyName, driversArray) => ({
  type: REFRESH_SIDEBAR_WITH_UNREAD_DRIVERS,
  payload: {
    companyName: companyName,
    drivers: driversArray,
  },
});

// const recoverCoachMsgWhileSidebarRefresh = (msg, id) => ({
//   type: RECOVER_COACH_MSG_WHILE_SIDEBAR_REFRESH,
//   payload: {
//     recoveredMsg: msg,
//     driverId: id,
//   },
// });

export const resetCoachMsgWhiledriverChange = () => ({
  type: RESET_COACH_MESSAGE_ON_DRIVER_CHANGE,
});

//Fetch unread drivers ids per company from api
export const getNewUnreadDrivers =
  () => async (dispatch, getState) => {
    const store = getState();
    let { auth, companies } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          const accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    //Recover the typed message by coach during sidebar refresh
    // if (driver) {
    //   dispatch(
    //     recoverCoachMsgWhileSidebarRefresh(typedMessage, driver.driverId)
    //   );
    // }

    if (companies !== null) {
      companies.map((comp) => {
        request
          .get(
            `${baseURL}/portal/coaching/Message/GetUnreadMessageCountPerCompany?companyId=${comp.companyId}&timePeriodMinutes=2`
          )
          .set("Authorization", `Bearer ${token}`)
          .retry(1, (err, res) => {
            if (res.unauthorized) {
              //console.log(`401 error, retry API, err is ${err}`);
              dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
              });
            }
          })
          .then((response) => {
            if (response.body.length > 0) {
              if (response.body.length === 1) {
                dispatch(
                  refreshSidebarWithUnreadDriver(
                    comp.companyName.toLowerCase(),
                    response.body[0]
                  )
                );
                dispatch(refreshDriverList(comp.companyName));
              } else {
                response.body.map((driverId) => {
                  dispatch(
                    refreshSidebarWithUnreadDriver(
                      comp.companyName.toLowerCase(),
                      driverId
                    )
                  );
                  dispatch(refreshDriverList(comp.companyName));
                });
              }
            }
          });
      });
    }
  };

const companiesFetched = (companies) => ({
  type: COMPANIES_FETCHED,
  payload: companies,
});

export const loadCompanies = () => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        const accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(`${baseURL}/Portal/Coaching/Companies/GetCompanies`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      batch(() => {
        dispatch(companiesFetched(response.body));
        getCompaniesNav(response.body, dispatch);

        //Uncomment this block to fetch all companies
        response.body.map((companyData) =>
          dispatch(loadCompanyGroups(companyData))
        );

        response.body.map((company) =>
          dispatch(loadCompanyFeatures(company?.companyId, company?.companyName))
        );

        //in dev mode: (no need to fetch every company)

        //dispatch(loadCompanyGroups("47b1fb8c-7b7d-4420-8646-69f7853704da"));

        // dispatch(loadCompanyGroups("d9c6d21c-dbf0-4a79-919f-b09e93e97bf8"));
        // dispatch(
        //   loadDrivers(
        //     "74607477-61f4-4e4e-9fb3-68af1f907ae8",
        //     "Albert Heijn Online"
        //   )
        // );
        // dispatch(loadCompanyGroups("74607477-61f4-4e4e-9fb3-68af1f907ae8"));
        // dispatch(
        //   loadDrivers("443325ee-786a-41c1-9bc8-d6d446a0af75", "SD-Insights")
        // );
        // dispatch(loadCompanyGroups("443325ee-786a-41c1-9bc8-d6d446a0af75"));

        //dispatch(loadCompanyGroups("0efb032c-2897-4f52-a809-35d51a53bd4b"));
      });
    })
    .catch(console.error);
};

const companyGroupsFetched = (groups, companyId) => ({
  type: COMPANY_GROUPS_COACHING_FETCHED,
  payload: {
    groups,
    companyId
  },
});

export const loadCompanyGroups = (companyData) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        const accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(`${baseURL}/portal/coaching/groups/GetCompanyGroups?companyId=${companyData.companyId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(companyGroupsFetched(response.body, companyData.companyId));
      // dispatch(
      //   // during development mode, so every change won't reload all the companies
      //   loadDrivers("47b1fb8c-7b7d-4420-8646-69f7853704da", "ABC Logistics")
      // );
      // dispatch(
      //   loadDrivers("d9c6d21c-dbf0-4a79-919f-b09e93e97bf8", "Jongeneel")
      // );
      dispatch(
        loadDrivers(companyData.companyId, companyData.companyName, response.body)
      );
    })
    .catch(console.error);
};

// creating company navigation
export const COMP_NAV_CREATED = "COMP_NAV_CREATED";

const compNavCreated = (companyNavigation) => ({
  type: COMP_NAV_CREATED,
  payload: companyNavigation,
});

const getCompaniesNav = (companies, dispatch) => {
  const result = {
    items: [
      {
        title: true,
        name: "Coaching",
      },
      {
        name: "Bedrijven",
        url: "/coaching",
        icon: "icon-speech",
        attributes: {
          id: "first_nav_toggle",
        },
        children: companies.map((company) => {
          return {
            name: company.companyName,
            // if the dropdown's child's url contains the dropdown's url, it sees it as active,
            // so the onclick won't redirect again to dropdown's url
            // url: `/coaching/${company.companyName.toLowerCase()}`,
            url: `/coaching/${company.companyName}`,
            icon: "icon-briefcase",
            attributes: {
              onClick: () => {
                return;
              },
              id: company.companyId,
            },
            children: [
              {
                name: "Chauffeurs laden...",
                // url: "/coaching",
                icon: "fa fa-spinner fa-lg fa-spin",
              },
            ],
          };
        }),
      },
    ],
  };
  dispatch(compNavCreated(result));
};
