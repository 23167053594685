// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI || window.location.origin, // "http://localhost:3000/", // '<OPTIONAL REDIRECT URI>'
    // validateAuthority: false,
    persistLoginPastSession: true,
    postLogoutRedirectUri: window.location.origin, //default is window.location.href
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
  },
  cache: {
    cacheLocation: "localStorage", // will give you Single Sign On accross tabs and user sessions
    // cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [
    // "openid", // added by default by the package (also profile, email, offline_access and User.Read)
    process.env.REACT_APP_SCOPES,
  ],
  authority:process.env.REACT_APP_AUTHORITY,
};

export const silentRequest = {
  scopes: [
    process.env.REACT_APP_SCOPES,
  ],
  authority:process.env.REACT_APP_AUTHORITY,
};

// export const forgotPasswordRequest = {
//     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_reset"
// }
